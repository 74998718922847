.kzo-animated .min-width-230 {
    min-width: 230px !important;
}

.kzo-animated .min-width-240 {
    min-width: 240px !important;
}

.kzo-animated .max-width-240 {
    max-width: 240px !important;
}

.life-insurance-calc {
    min-height: 510px;
    border-bottom: 3px solid gray;
    padding: 0;
    position: relative;
    z-index: 5;
    transition: min-height .4s ease;
    background-color: #b0d5f3;
}

.life-insurance-calc:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: opacity .4s ease;
    -webkit-animation: bg-color-animation 3s infinite linear;
    animation: bg-color-animation 3s infinite linear
}

@media (max-width: 800px) {
    .life-insurance-calc {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: -1;
    }
}

@-webkit-keyframes bg-color-animation {
    0% {
        background-color: #b0d5f3
    }
    50% {
        background-color: #dcedfa
    }
    100% {
        background-color: #b0d5f3
    }
}

@keyframes bg-color-animation {
    0% {
        background-color: #b0d5f3
    }
    50% {
        background-color: #dcedfa
    }
    100% {
        background-color: #b0d5f3
    }
}

.life-insurance-animations-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    max-height: 480px;
    opacity: 1;
    transition: opacity .4s ease;
}

.life-insurance-animations-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.life-insurance-animations-bg-rain {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;
    left: 0;
    bottom: 0;
    opacity: 0;
    display: none;
    transition: opacity .2s ease;
}

.life-insurance-animations-cloud {
    position: absolute;
    transition: opacity .4s ease, fill .4s ease;
}

.life-insurance-animations-cloud-1 {
    width: 5.001vw;
    height: 3.204vw;
    max-width: 64px;
    max-height: 41px;
    left: 82%;
    bottom: 80%;
}

.life-insurance-animations-cloud-1.active {
    fill: #01628C;
}

.life-insurance-animations-cloud-2 {
    width: 8.205vw;
    height: 4.454vw;
    max-width: 105px;
    max-height: 57px;
    left: 60%;
    bottom: 79%;
    fill: #FFF;
    opacity: .5;
}

.life-insurance-animations-cloud-2.active {
    fill: #01628C;
}

.life-insurance-animations-cloud-2-1 {
    width: 5.001vw;
    height: 3.204vw;
    max-width: 64px;
    max-height: 41px;
    left: -1.3%;
    top: 1%;
    fill: #FFF;
    opacity: .3;
}

.life-insurance-animations-cloud-3 {
    width: 12.268vw;
    max-width: 157px;
    max-height: 150px;
    left: 81%;
    bottom: 64%;
    opacity: 0;
}

.life-insurance-animations-baloon {
    position: absolute;
    width: 8.986vw;
    height: 12.034vw;
    max-width: 115px;
    max-height: 154px;
    right: 22%;
    bottom: 80%;
    opacity: .3;
}

/*Money*/

.life-insurance-animations-money-holder {
    position: absolute;
    left: 21%;
    bottom: 19px;
    z-index: 5;
    opacity: 0;
    transition: opacity .2s ease;
}

.life-insurance-animations-money {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    opacity: 0;
}

.life-insurance-animations-money-1 {
    width: 7.97vw;
    height: 6.486vw;
    max-width: 102px;
    max-height: 83px;
    -webkit-transform: translateX(-104%);
    -ms-transform: translateX(-104%);
    transform: translateX(-104%);
}

.life-insurance-animations-money-2 {
    width: 14.691vw;
    height: 6.486vw;
    max-width: 188px;
    max-height: 83px;
    -webkit-transform: translateX(-44%);
    -ms-transform: translateX(-44%);
    transform: translateX(-44%);
}

.life-insurance-animations-money-3 {
    width: 16.567vw;
    height: 8.596vw;
    max-width: 212px;
    max-height: 112px;
}

.life-insurance-animations-money-4 {
    width: 16.567vw;
    height: 10.627vw;
    max-width: 212px;
    max-height: 136px;
}

.life-insurance-animations-money-5 {
    width: 16.567vw;
    height: 12.816vw;
    max-width: 212px;
    max-height: 164px;
}

.life-insurance-animations-money-6 {
    width: 16.567vw;
    height: 14.769vw;
    max-width: 212px;
    max-height: 189px;
}

.life-insurance-animations-money-7 {
    width: 16.567vw;
    height: 16.957vw;
    max-width: 212px;
    max-height: 217px;
}

.life-insurance-animations-money-8 {
    width: 16.567vw;
    height: 19.224vw;
    max-width: 212px;
    max-height: 246px;
}

.life-insurance-animations-money-9 {
    width: 16.567vw;
    height: 21.724vw;
    max-width: 212px;
    max-height: 278px;
}

/*Trees holder*/

.life-insurance-animations-bg-trees-holder {
    width: 100%;
    max-width: 1002px;
    max-height: 150px;
    z-index: 3;
    position: relative;
    margin: 0 auto;
}

.life-insurance-animations-bank-holder {
    position: absolute;
    left: 72%;
    bottom: 0;
    transition: opacity .2s ease;
}

.life-insurance-animations-bank {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    opacity: 0;
}

.life-insurance-animations-bank-1 {
    width: 6.408vw;
    height: 7.736vw;
    max-width: 82px;
    max-height: 99px;
}

.life-insurance-animations-bank-2 {
    width: 9.064vw;
    height: 7.736vw;
    max-width: 116px;
    max-height: 99px;
}

.life-insurance-animations-bank-3 {
    width: 11.409vw;
    height: 9.533vw;
    max-width: 146px;
    max-height: 122px;
}

.life-insurance-animations-bank-4 {
    width: 12.425vw;
    height: 9.455vw;
    max-width: 159px;
    max-height: 121px;
}

.life-insurance-animations-bank-5 {
    width: 12.503vw;
    height: 11.096vw;
    max-width: 160px;
    max-height: 142px;
}

.life-insurance-animations-bank-6 {
    width: 12.503vw;
    height: 15.472vw;
    max-width: 160px;
    max-height: 198px;
}

.life-insurance-animations-bank-7 {
    width: 17.973vw;
    height: 15.472vw;
    max-width: 230px;
    max-height: 198px;
}

.life-insurance-animations-bank-8 {
    width: 19.458vw;
    height: 16.098vw;
    max-width: 249px;
    max-height: 206px;
}

.life-insurance-animations-bank-9 {
    width: 21.021vw;
    height: 18.676vw;
    max-width: 269px;
    max-height: 239px;
}

.life-insurance-animations-bg-trees {
    width: 100%;
    max-width: 913px;
    position: relative;
}

.life-insurance-animations-banch-holder {
    width: 6.642vw;
    height: 3.672vw;
    max-width: 85px;
    max-height: 47px;
    bottom: 5px;
    position: relative;
    display: inline-block;
}

.life-insurance-animations-banch {
    width: 100%;
    height: 100%;
}

.life-insurance-animations-banch-people {
    width: 6.329vw;
    height: 5.548vw;
    max-width: 81px;
    max-height: 71px;
    position: absolute;
    bottom: -10%;
    right: 0;
    opacity: 0;
    transition: opacity .4s ease;
}

@media all and (max-width: 680px) {
    .life-insurance-animations-banch-holder {
        bottom: 0
    }
}

.life-insurance-animations-bird-holder {
    position: absolute;
    z-index: 10;
    opacity: 1;
    left: 50%;
    top: -185%;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    display: none;
}

.life-insurance-animations-bird-wing-holder {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: absolute;
}

.life-insurance-animations-bird-wing-front-holder {
    -webkit-transform-origin: center 13.4px;
    -ms-transform-origin: center 13.4px;
    transform-origin: center 13.4px;
    left: 18px;
    top: 12px;
    -webkit-animation: bird-wing-fly--front .6s infinite linear;
    animation: bird-wing-fly--front .6s infinite linear;
}

@-webkit-keyframes bird-wing-fly--front {
    0% {
        -webkit-transform: rotate(-40deg) rotateX(0deg) scaleY(1) scaleX(1);
        transform: rotate(-40deg) rotateX(0deg) scaleY(1) scaleX(1)
    }
    50% {
        -webkit-transform: rotate(-40deg) rotateX(180deg) scaleY(1.9) scaleX(1.1);
        transform: rotate(-40deg) rotateX(180deg) scaleY(1.9) scaleX(1.1)
    }
    100% {
        -webkit-transform: rotate(-40deg) rotateX(360deg) scaleY(1) scaleX(1);
        transform: rotate(-40deg) rotateX(360deg) scaleY(1) scaleX(1)
    }
}

@keyframes bird-wing-fly--front {
    0% {
        -webkit-transform: rotate(-40deg) rotateX(0deg) scaleY(1) scaleX(1);
        transform: rotate(-40deg) rotateX(0deg) scaleY(1) scaleX(1)
    }
    50% {
        -webkit-transform: rotate(-40deg) rotateX(180deg) scaleY(1.9) scaleX(1.1);
        transform: rotate(-40deg) rotateX(180deg) scaleY(1.9) scaleX(1.1)
    }
    100% {
        -webkit-transform: rotate(-40deg) rotateX(360deg) scaleY(1) scaleX(1);
        transform: rotate(-40deg) rotateX(360deg) scaleY(1) scaleX(1)
    }
}

.life-insurance-animations-bird-body {
    width: 70px;
    height: 42px;
}

.life-insurance-animations-bird-wing-holder {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: absolute;
}

.life-insurance-animations-bird-wing-back-holder {
    top: 22px;
    left: 32px;
    z-index: -1;
    -webkit-animation: bird-wing-fly--back .6s infinite linear;
    animation: bird-wing-fly--back .6s infinite linear;
}

.life-insurance-animations-money-bag-holder {
    width: 30px;
    position: absolute;
    top: 97%;
    left: 87%;
    opacity: 1;
    transition: all .5s ease;
    bottom: 50px;
    height:5vw;
}

.life-insurance-animations-money-bag {
    width: 100%;
    height: 100%;
}

.life-insurance-animations-money-bag-rope {
    width: 8px;
    height: 40px;
    position: absolute;
    bottom: 65%;
    left: 5%;
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
    opacity: 0;
}

.life-insurance-animations-money-bag-extra-holder {
    opacity: 0;
    transition: opacity .2s ease;
}

.life-insurance-animations-money-bag-1 {
    width: 1.797vw;
    height: 2.109vw;
    max-width: 23px;
    max-height: 27px;
    position: absolute;
    bottom: -7%;
    right: 0;
    opacity: 0;
    transition: opacity .2s ease;
}

.life-insurance-animations-money-bag-2 {
    width: 1.797vw;
    height: 2.109vw;
    max-width: 23px;
    max-height: 27px;
    position: absolute;
    bottom: -7%;
    right: 3%;
    opacity: 0;
    transition: opacity .2s ease;
}

.life-insurance-animations-money-bag-3 {
    width: 1.797vw;
    height: 2.109vw;
    max-width: 23px;
    max-height: 27px;
    position: absolute;
    bottom: -3%;
    right: 1.4%;
    opacity: 0;
    transition: opacity .2s ease;
}

.life-insurance-animations-bg-ground {
    width: 100%;
    max-height: 151px;
    height: 9.44vw;
    z-index: 2;
    margin-top: -3vw;
}

@media (min-width: 1450px) {
    .life-insurance-animations-bg-ground {
        margin-top: -43.5px;
    }
}

/*Animated part*/

/*Cake*/
.life-insurance-animations-cake-holder {
    position: absolute;
    right: 70%;
    bottom: 20px;
    width: 13.45%;
    z-index: 1;
    opacity: 0;
    transition: .2s opacity ease;
}

.life-insurance-animations-cake {
    width: 100%;
    height: 17.192vw;
    max-height: 220px;
}

.life-insurance-animations-cake-candle {
    opacity: 0;
    width: 4.09%;
    height: 3.516vw;
    max-height: 45px;
    position: absolute;
    transition: .2s opacity ease;
}

.life-insurance-animations-cake-candle-1 {
    top: 1%;
    left: 41%;
}

.life-insurance-animations-cake-candle-2 {
    top: 1%;
    left: 54%;
}

.life-insurance-animations-cake-candle-3 {
    top: 13%;
    left: 32%;
}

.life-insurance-animations-cake-candle-4 {
    top: 16%;
    left: 38%;
}

.life-insurance-animations-cake-candle-5 {
    top: 17%;
    left: 46%;
}

.life-insurance-animations-cake-candle-6 {
    top: 17%;
    left: 55%;
}

.life-insurance-animations-cake-candle-7 {
    top: 14%;
    left: 63%;
}

.life-insurance-animations-cake-candle-8 {
    left: 26%;
    top: 24%;
}

.life-insurance-animations-cake-candle-9 {
    top: 30%;
    left: 30.5%;
}

.life-insurance-animations-cake-candle-10 {
    top: 32%;
    left: 39%;
}

.life-insurance-animations-cake-candle-11 {
    top: 33%;
    left: 49%;
}

.life-insurance-animations-cake-candle-12 {
    top: 31%;
    left: 59%;
}

.life-insurance-animations-cake-candle-13 {
    top: 29%;
    left: 66%;
}

.life-insurance-animations-cake-candle-14 {
    top: 24%;
    left: 70%;
}

.life-insurance-animations-people-chopper {
    position: absolute;
    width: 19.81%;
    height: 26.407vw;
    max-height: 338px;
    right: 25%;
    bottom: 38px;
}

@media all and (max-width: 1280px) {
    .life-insurance-animations-people-chopper {
        bottom: 3vw
    }
}

.life-insurance-animations-people-chopper-holder {
    position: absolute;
    max-height: 243px;
    height: 18.985vw;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 1;
    transition: opacity .2s ease;
}

.life-insurance-animations-chopper-vane {
    position: absolute;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-animation: vane-loop 1s infinite linear;
    animation: vane-loop 1s infinite linear
}

@-webkit-keyframes vane-loop {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }
    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg)
    }
}

@keyframes vane-loop {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }
    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg)
    }
}

.life-insurance-animations-umbrella {
    position: absolute;
    width: 17.817vw;
    height: 17.582vw;
    max-width: 228px;
    max-height: 225px;
    z-index: 4;
    top: -36.5%;
    right: 50.5%;
    transition: opacity .2s ease;
    opacity: 0;
}

.life-insurance-animations-man-holder {
    width: 7.033vw;
    height: 18.989vw;
    max-width: 109px;
    max-height: 243px;
    position: absolute;
    left: 13%;
    z-index: 2;
    opacity: 1;
    transition: opacity .2s ease;
}

.life-insurance-animations-chopper-rope {
    background-color: #f1ea24;
    width: 2px;
    height: 55%;
    display: block;
    position: absolute;
    bottom: 80%;
    z-index: 2;
    opacity: 0;
}

.life-insurance-animations-chopper-rope:after {
    content: '';
    display: block;
    width: 6px;
    max-height: 28px;
    height: 2.188vw;
    background-color: #f1ea24;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    left: -2px;
}

.life-insurance-animations-chopper-rope-right-man {
    left: 27%;
    bottom: 70%;
}

.life-insurance-animations-man {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    width: 7.033vw;
    height: 18.989vw;
    max-width: 90px;
    max-height: 243px;
    z-index: 1;
    opacity: 1;
    transition: opacity .1s ease;
}

.life-insurance-animations-man-clothes {
    opacity: 0;
    z-index: 1;
}

.life-insurance-animations-man-insurance {
    width: 9.221vw;
    max-width: 118px;
    margin-left: -15%;
    opacity: 0;
    transition: none;
}

.life-insurance-animations-chopper-rope-left-man {
    right: 27%;
    bottom: 70%;
}

.life-insurance-animations-woman-holder {
    width: 8.517vw;
    height: 18.989vw;
    max-width: 109px;
    max-height: 243px;
    position: absolute;
    right: 13%;
    left: 44.5%;
    z-index: 1;
    opacity: 1;
    transition: opacity .2s ease;
}

.life-insurance-animations-chopper-rope-right-woman {
    left: 30%;
    bottom: 67%;
}

.life-insurance-animations-woman {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 8.517vw;
    height: 18.989vw;
    max-width: 109px;
    max-height: 243px;
    z-index: 1;
    opacity: 1;
    transition: opacity .1s ease;
}

.life-insurance-animations-woman-clothes {
    opacity: 0;
    z-index: 1;
}

.life-insurance-animations-woman-insurance {
    width: 7.658vw;
    max-width: 98px;
    margin-left: 2%;
    opacity: 0;
    transition: none;
}

.life-insurance-animations-chopper-rope-left-woman {
    right: 28%;
    bottom: 67%;
}

.life-insurance-animations-child-holder {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.life-insurance-animations-child {
    bottom: -20px;
    position: absolute;
    opacity: 0;
    justify-content: center;
    transition: opacity .2s ease;
}

.life-insurance-animations-child-1 {
    width: 7.658vw;
    max-width: 98px;
    max-height: 198px;
    left: 31%;
    bottom: 1%;
}

.life-insurance-animations-child-baby {
    width: 4.61vw;
    height: 7vw;
    max-width: 59px;
    max-height: 85px;
    opacity: 1;
}

.life-insurance-animations-child svg {
    position: relative;
    left: 0;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}

.life-insurance-animations-child svg.hidden {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0 !important;
}

.life-insurance-animations-child-1-1 {
    width: 4.688vw;
    height: 8.125vw;
    max-height: 104px;
    max-width: 60px;
}

.life-insurance-animations-child-1-2 {
    width: 4.844vw;
    height: 9.298vw;
    max-width: 62px;
    max-height: 119px;
}

.life-insurance-animations-child-1-3 {
    width: 7.501vw;
    height: 11.485vw;
    max-width: 96px;
    max-height: 147px;
}

.life-insurance-animations-child-1-4 {
    width: 7.657vw;
    height: 12.969vw;
    max-width: 98px;
    max-height: 166px;
}

.life-insurance-animations-child-1-5 {
    width: 5.704vw;
    height: 15.469vw;
    max-width: 73px;
    max-height: 198px;
}

.life-insurance-animations-child-hat {
    width: 3.204vw;
    height: 2.031vw;
    max-width: 41px;
    max-height: 26px;
    top: -9%;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity .2s ease;
}

.life-insurance-animations-child-vest {
    opacity: 0;
    position: absolute;
    height: 30%;
    top: 21%;
    transition: opacity .2s ease;
}

.life-insurance-animations-child-2 {
    width: 7.658vw;
    max-width: 98px;
    max-height: 198px;
    left: 17%;
    bottom: -13px;
}

.life-insurance-animations-child-2-1 {
    width: 4.688vw;
    height: 8.125vw;
    max-height: 104px;
    max-width: 60px;
}

.life-insurance-animations-child-2-2 {
    width: 4.923vw;
    max-width: 63px;
    height: 121px;
}

.life-insurance-animations-child-2-3 {
    width: 7.501vw;
    height: 11.485vw;
    max-width: 96px;
    max-height: 147px;
}

.life-insurance-animations-child-2-4 {
    width: 7.657vw;
    max-width: 98px;
    height: 12.969vw;
    max-height: 166px;
}

.life-insurance-animations-child-2-5 {
    width: 5.704vw;
    height: 15.469vw;
    max-width: 73px;
    max-height: 198px;
}

.life-insurance-animations-child-3 {
    width: 7.658vw;
    max-width: 98px;
    max-height: 198px;
    left: 47%;
    bottom: 2px;
}

.life-insurance-animations-child-3-1 {
    width: 4.688vw;
    height: 8.125vw;
    max-height: 104px;
    max-width: 60px;
}

.life-insurance-animations-child-3-2 {
    width: 4.844vw;
    height: 9.298vw;
    max-width: 62px;
    max-height: 119px;
}

.life-insurance-animations-child-3-3 {
    width: 7.501vw;
    height: 11.485vw;
    max-width: 96px;
    max-height: 147px;
}

.life-insurance-animations-child-3-4 {
    width: 7.657vw;
    height: 12.969vw;
    max-width: 98px;
    max-height: 166px;
}

.life-insurance-animations-child-3-5 {
    width: 5.704vw;
    height: 15.469vw;
    max-width: 73px;
    max-height: 198px;
}

.life-insurance-animations-child-4 {
    width: 7.658vw;
    max-width: 98px;
    max-height: 198px;
    left: 40%;
    bottom: -15px;
}

.life-insurance-animations-child-4-1 {
    width: 4.688vw;
    height: 8.125vw;
    max-height: 104px;
    max-width: 60px;
}

.life-insurance-animations-child-4-2 {
    width: 4.844vw;
    height: 9.298vw;
    max-width: 62px;
    max-height: 119px;
}

.life-insurance-animations-child-4-3 {
    width: 7.501vw;
    height: 11.485vw;
    max-width: 96px;
    max-height: 147px;
}

.life-insurance-animations-child-4-4 {
    width: 7.657vw;
    height: 12.969vw;
    max-width: 98px;
    max-height: 166px;
}

.life-insurance-animations-child-4-5 {
    width: 5.704vw;
    height: 15.469vw;
    max-width: 73px;
    max-height: 198px;
}

.life-insurance-animations-child-5 {
    width: 7.658vw;
    max-width: 98px;
    max-height: 198px;
    left: 24%;
    bottom: -18px;
}

.life-insurance-animations-child-5-1 {
    width: 4.688vw;
    height: 8.125vw;
    max-height: 104px;
    max-width: 60px;
}

.life-insurance-animations-child-5-2 {
    width: 4.844vw;
    height: 9.298vw;
    max-width: 62px;
    max-height: 119px;
}

.life-insurance-animations-child-5-3 {
    width: 7.501vw;
    height: 11.485vw;
    max-width: 96px;
    max-height: 147px;
}

.life-insurance-animations-child-5-4 {
    width: 7.657vw;
    height: 12.969vw;
    max-width: 98px;
    max-height: 166px;
}

.life-insurance-animations-child-5-5 {
    width: 5.704vw;
    height: 15.469vw;
    max-width: 73px;
    max-height: 198px;
}

@media all and (max-width: 670px) {
    .life-insurance-animations-child-2 {
        bottom: -7%
    }

    .life-insurance-animations-child-4, .life-insurance-animations-child-5 {
        bottom: -10px
    }
}

.life-insurance-animations-pig-holder {
    position: absolute;
    width: 16.882vw;
    height: 10.316vw;
    max-width: 216px;
    max-height: 132px;
    bottom: -3%;
    left: -90%;
    z-index: 2;
    transition: opacity .4s ease;
    opacity: 0;
}

.life-insurance-animations-pig {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    transition: max-height .1s ease;
}

.life-insurance-animations-pig-1 {
    width: 5.94vw;
    height: 4.298vw;
    max-width: 76px;
    max-height: 55px;
}

.life-insurance-animations-pig-2 {
    width: 10.238vw;
    height: 7.425vw;
    max-width: 131px;
    max-height: 95px;
}

.life-insurance-animations-pig-3 {
    width: 12.974vw;
    height: 10.316vw;
    max-width: 166px;
    max-height: 132px;
}

.life-insurance-animations-pig-4 {
    width: 16.882vw;
    height: 10.238vw;
    max-width: 216px;
    max-height: 131px;
}

.life-insurance-animations-chopper-holder {
    z-index: 2;
    position: absolute;
    width: 38.532%;
    height: 41px;
    max-height: 95px;
    right: -112%;
    bottom: 77%;
    opacity: .3;
}

.life-insurance-animations-chopper {
    position: absolute;
    top: 3px;
    left: 0;
    width: 100%;
    height: 100%;
}

.life-insurance-animations-stork-holder {
    position: absolute;
    width: 7.97vw;
    height: 1.875vw;
    max-width: 102px;
    max-height: 24px;
    bottom: 56%;
    left: 100%;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    z-index: 2;
    opacity: 0;
}

.life-insurance-animations-stork {
    width: 100%;
    height: 100%;
}

.life-insurance-animations-stork-body {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.life-insurance-animations-stork-wing {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 32%;
    top: -50%;
    -webkit-transform-origin: 45% 80%;
    -ms-transform-origin: 45% 80%;
    transform-origin: 45% 80%;
    -webkit-animation: stork-wing-fly 1s infinite linear;
    animation: stork-wing-fly 1s infinite linear;
    -webkit-transform: transform3d(0, 0, 0);
    -ms-transform: transform3d(0, 0, 0);
    transform: transform3d(0, 0, 0);
}

@-webkit-keyframes stork-wing-fly {
    0% {
        -webkit-transform: rotateX(0deg) scale(1) translateY(0px);
        transform: rotateX(0deg) scale(1) translateY(0px)
    }
    50% {
        -webkit-transform: rotateX(180deg) scale(1.2) translateY(-5px);
        transform: rotateX(180deg) scale(1.2) translateY(-5px)
    }
    100% {
        -webkit-transform: rotateX(360deg) scale(1) translateY(0px);
        transform: rotateX(360deg) scale(1) translateY(0px)
    }
}

@keyframes stork-wing-fly {
    0% {
        -webkit-transform: rotateX(0deg) scale(1) translateY(0px);
        transform: rotateX(0deg) scale(1) translateY(0px)
    }
    50% {
        -webkit-transform: rotateX(180deg) scale(1.2) translateY(-5px);
        transform: rotateX(180deg) scale(1.2) translateY(-5px)
    }
    100% {
        -webkit-transform: rotateX(360deg) scale(1) translateY(0px);
        transform: rotateX(360deg) scale(1) translateY(0px)
    }
}

@media all and (max-width: 800px) {
    .life-insurance-animations-stork {
        position: absolute;
        top: 1.4vh
    }
}

.life-insurance-animations-stork-bag {
    width: 2.344vw;
    height: 4.063vw;
    max-width: 30px;
    max-height: 52px;
    position: absolute;
    bottom: 51.8%;
    left: 99.3%;
    z-index: 10;
    opacity: 0;
}

/*FORM STYLES*/
.kzo-animated .inner-section {
    position: relative;
    z-index: 999;
    width: 87%;
    z-index: 6;
}

.life-insurance-calc-title {
    padding-left: 0 !important;
    margin-top: 10px !important;
}

.life-insurance-step {
    margin-top: 42px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.life-insurance-calc-step-holder {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.life-insurance-step-wrapper {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 430px;
}

.life-insurance-step.skip .life-insurance-step-num {
    cursor: not-allowed !important;
    opacity: .8
}

.life-insurance-step.active {
    z-index: 10
}

.life-insurance-step.active .life-insurance-step-questions-container {
    visibility: visible;
    opacity: 1;
    transition: opacity .2s ease 1.3s;
}

.life-insurance-step.active .life-insurance-step-num {
    cursor: initial;
    background-color: #ec6502 !important;
    left: 50%!important;;
    margin-left: 0;
}

.life-insurance-step.answered ~ .life-insurance-step.linked-step .life-insurance-step-num {
    cursor: pointer
}

.life-insurance-step.answered .life-insurance-step-num {
    background-color: #005ca9;
    cursor: pointer;
    left: 47px;
}

.life-insurance-step:first-of-type {
    z-index: 0
}

.life-insurance-step:first-of-type .life-insurance-step-num:before {
    content: '';
    display: block;
    position: absolute;
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20%20width%3D%27100%27%20height%3D%27100%27%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%22-244%20248%2010%2010.1%22%20%3E%3Ccircle%20fill%3D%22%23FFFFFF%22%20cx%3D%22-241.5%22%20cy%3D%22250.5%22%20r%3D%222.5%22/%3E%3C/svg%3E');
    background-size: 10px;
    top: 50%;
    width: 10000%;
    height: 5px;
    left: 100%;
    -webkit-transform: translateX(0) translateZ(-10px) translateY(-50%);
    transform: translateX(0) translateZ(-10px) translateY(-50%)
}

.life-insurance-step-num {
    font-size: 18px;
    font-family: StoneSansITCPro-Medium, sans-serif;
    color: #fff;
    font-weight: 700;
    background-color: #a7a7a7;
    width: 42px;
    height: 42px;
    box-sizing: border-box;
    border: 3px solid #f4f4f4;
    border-radius: 100%;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%) translateY(-100%);
    -ms-transform: translateX(-50%) translateY(-100%);
    transform: translateX(-50%) translateY(-100%);
    z-index: 1;
    margin: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background .2s ease, opacity .4s ease, margin-left .5s ease, left .5s ease;
    padding: 8px 12px;
    left: 100%;
    margin-left: -21px;
}

.life-insurance-step-questions {
    -webkit-justify-content: center;
    min-width: 160px;
    max-width: 210px
}

.life-insurance-step-questions-container {

    position: relative;
    margin-top: -12px;
    opacity: 0;
    background: rgba(255, 255, 255, .95);
    border-radius: 48%;
    padding: 25px 25px 48px;
    transition: opacity .2s ease .2s;
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.life-insurance-step-question-input-container {
    text-align: center;
}

.life-insurance-step-question + .life-insurance-step-question {
    margin-top: 9px
}

.life-insurance-step-question-title {
    text-align: center;
    font-size: 14px;
    font-family: Arial;
    font-weight: 700;
    max-width: 85%;
    margin: 0 auto;
    padding-bottom: 13px;
    padding-top: 5px;
    line-height: 20px;
}

.life-insurance-step-next {
    position: absolute;
    bottom: -10px;
    right: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    color: #fff;
    border: 2px solid #fff !important;
    padding: 8px 24px;
    font-family: StoneSansITCPro-Medium, Arial, sans-serif;
    display: none;
    pointer-events: none;
    transition: opacity .2s ease 0s
}

.life-insurance-step-next.disabled {
    cursor: not-allowed !important;
    opacity: .5 !important;
    pointer-events: visible !important;
}

.life-insurance-step-next.disabled:hover {
    cursor: not-allowed !important;
    opacity: .5 !important;
}

.life-insurance-step-next.show {
    pointer-events: all;
    cursor: pointer;
    opacity: 1
}

@media (max-width: 800px) {
    .life-insurance-step-num {
        position: relative;
        left: 50%;
        margin-left: 0;
    }
    .life-insurance-step:first-of-type {
        z-index: 11;
        margin-top: 42px!important;
    }
    .life-insurance-step:first-of-type .life-insurance-step-num:before{
        content:none;
    }
    .life-insurance-step.answered{
        z-index: 10;
    }

    .life-insurance-step-questions-container {
        margin-top: -52px;
    }

    .life-insurance-step.answered .life-insurance-step-num {
        left: 50% !important;
    }

    .life-insurance-step.inactive .life-insurance-step-questions-container {
        display: none;
    }

    .life-insurance-step.active .life-insurance-step-questions-container {
        display: block;
    }

    .life-insurance-step.answered .life-insurance-step-questions-container {
        display: block;
        opacity:1;
        visibility:visible;
    }
    .life-insurance-step:last-of-type{
        z-index:-1;
    }
    .life-insurance-animations-bg-trees {
        width: 90%;
    }
    .life-insurance-animations-money-bag-holder{
        left: 75%!important;
    }
}

/*Input styles*/

.kzo-animated .rangeslider {
    background-color: transparent;
    box-shadow: none;
    height: 10px;
    cursor: pointer;
    padding: 10px 0;
    position: relative
}

.kzo-animated .rangeslider:before {
    content: '';
    background-color: #c6c7c7;
    position: absolute;
    width: 100%;
    height: inherit;
    border-radius: 3px;
    left: 0;
    top: 5px;
}

.kzo-animated .rangeslider__fill {
    background-color: #7fadd4;
    box-shadow: none;
    border-radius: 3px;
    transition: width .2s ease;
    height: inherit;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.kzo-animated .rangeslider__handle {
    transition: left .2s ease, border-color .4s ease-in, box-shadow .2s ease-in;
    top: -3px;
    width: 26px;
    height: 26px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .1);
    background: #fff;
    border: 2px solid #9ea5ae
}

.kzo-animated .input__range:focus + .rangeslider .rangeslider__handle {
    box-shadow: 0 0 8px #7fadd4 !important
}

.kzo-animated .rangeslider__handle::after {
    display: none;
}

.kzo-animated .rangeslider__handle:hover, .rangeslider__handle:active {
    cursor: grab;
    cursor: -webkit-grab;
    border-color: #7fadd4;
    box-shadow: 0 0 5px 2px rgba(125, 171, 212, .4)
}

.kzo-animated input[type=text], .kzo-animated input[type=email], .kzo-animated input[type=password], .kzo-animated input[type=number] {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #282B34;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    outline: none;
    text-align: center;
    height: 35px;
    line-height: 35px;
}

.kzo-animated .input-range-value-holder {
    margin-bottom: 18px;
}

.kzo-animated input[type=number] {
    -moz-appearance: textfield
}

.kzo-animated input[type=number]::-webkit-inner-spin-button, .kzo-animated input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.kzo-animated .input[type=number] {
    border-radius: 1px
}

.kzo-animated .input-range-value {
    font-size: 16px !important;
    font-family: Arial;
    text-align: center
}

.kzo-animated .input-radio-label {
    display: block;
    font-weight: normal;
}

.kzo-animated .input-radio {
    margin-left: 10px;
    margin-top: 5px;
}

.kzo-animated .input-radio-label + .input-radio-label {
    margin-top: 10px;
    margin-bottom: 10px;
}

.kzo-animated .input-range-wrapper-compact {
    width: 160px;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 5px;
}

.kzo-animated .input-range-wrapper-compact .rangeslider {
    display: inline-block;
    width: 110px;
    top: 6px;
}

.kzo-animated .input-range-value-holder-compact {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 6px
}

.kzo-animated .input-number-container {
    padding-bottom: 13px;

}

.kzo-animated .input-number-btn {
    background-color: #7fadd4;
    width: 25px;
    color: #fff;
    font-size: 20px;
    text-shadow: 0 1px rgba(0, 0, 0, .1);
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}

.kzo-animated .input-number-btn:first-of-type {
    margin-left: 12px
}

.kzo-animated .input-number-btn:last-of-type {
    margin-left: 5px
}

.kzo-animated .mainColor {
    background-color: #005ca9;
}

.kzo-animated .mainColorTxt {
    color: #005ca9;
}

.kzo-animated .btn {
    border: 0;
    cursor: pointer;
    padding: 11px 20px;
    font-family: StoneSansITCPro-Medium, arial, sans-serif;
    text-decoration: none;
    transition: opacity .1s ease;
}

.kzo-animated .btn .icon {
    margin-right: 7px
}

.kzo-animated .btn i {
    position: relative;
    font-size: 18px;
    background-image: none
}

.kzo-animated .btn i.fa-caret-right {
    margin-left: 10px
}

.kzo-animated .btn i.fa-caret-left, .kzo-animated .btn i.fa-repeat {
    margin-right: 10px
}

.kzo-animated .btn:link {
    font-style: normal
}

.kzo-animated .btn:hover {
    opacity: .9;
    text-shadow: 0 -1px rgba(0, 0, 0, .2);
    color: #fff;

}

/* Step positions*/
.kzo-animated .step-position {
    position: absolute;
    opacity: 1;
    left: 100%;
}

@media (max-width: 800px) {
    .kzo-animated .step-position {
        position: relative;
        max-width: 240px;
        margin: 60px auto;
        left:0;
    }
}

/*Step positions large*/
.kzo-animated #step-1.inactive {
    margin-left: -450px;
}

.kzo-animated #step-1.active {
    left: 0!important;
}

.kzo-animated #step-1.answered {
    left: 0;
}

.kzo-animated #step-2.inactive {
    margin-left: -420px;
}

.kzo-animated #step-2.active {
    left: 25%!important;
}

.kzo-animated #step-2.answered {
    left: 30px;
}

.kzo-animated #step-3.inactive {
    margin-left: -410px;
}

.kzo-animated #step-3.active {
    left: 7%!important;
}

.kzo-animated #step-3.answered {
    left: 60px;
}

.kzo-animated #step-4.inactive {
    margin-left: -388px;
}

.kzo-animated #step-4.active {
    left: 32%!important;
}

.kzo-animated #step-4.answered {
    left: 90px;
}

.kzo-animated #step-5.inactive {
    margin-left: -380px;
}

.kzo-animated #step-5.active {
    left: 15%!important;
}

.kzo-animated #step-5.answered {
    left: 120px;
}

.kzo-animated #step-6.inactive {
    margin-left: -350px;
}

.kzo-animated #step-6.active {
    left: 15%!important;
}

.kzo-animated #step-6.answered {
    left: 150px;
}

.kzo-animated #step-7.inactive {
    margin-left: -320px;
}

.kzo-animated #step-7.active {
    left: 16%!important;
}

.kzo-animated #step-7.answered {
    left: 180px
}

.kzo-animated #step-8.inactive {
    margin-left: -270px;
}

.kzo-animated #step-8.active {
    left: 17%!important;
}

.kzo-animated #step-8.answered {
    left: 210px
}

.kzo-animated #step-9.inactive {
    margin-left: -230px;
}

.kzo-animated #step-9.active {
    left: 18%!important;
}

.kzo-animated #step-9.answered {
    left: 240px;
}

.kzo-animated .life-insurance-step.answered.inactive {
    left: 100% !important;
}

.kzo-animated .life-insurance-step.answered.inactive .life-insurance-step-num {
    left: 100% !important;
}

@media (max-width: 800px) {
    /*Step positions small*/
    .kzo-animated #step-1.inactive {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-1.active {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-1.answered {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-2.inactive {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-2.active {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-2.answered {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-3.inactive {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-3.active {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-3.answered {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-4.inactive {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-4.active {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-4.answered {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-5.inactive {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-5.active {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-5.answered {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-6.inactive {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-6.active {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-6.answered {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-7.inactive {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-7.active {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-7.answered {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-8.inactive {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-8.active {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-8.answered {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-9.inactive {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-9.active {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated #step-9.answered {
        left:0!important;
        margin-left:auto;
    }

    .kzo-animated .life-insurance-step.answered.inactive {
        left: 100% !important;
    }

    .kzo-animated .life-insurance-step.answered.inactive {
        left: auto !important;
    }

    .kzo-animated .life-insurance-step.answered.inactive .life-insurance-step-num {
        left: 50% !important;
    }
}

/*Responsive fixes*/
.kzo-animated-top {
    background-color: transparent;
}

.kzo-animated-header {
    background-color: #fff;
}

/**/

.kzo-animated .upper-section {
    float: left;
    width: 100%;
    position: relative;
}

.kzo-animated .lower-section {
    float: left;
    width: 100%;
    position: relative;
    padding-top: 30px;
}

.life-insurance-calc-offer-info {
    top: 0;
    left: 0;
    z-index: 200;
    margin-left: 40px;
    opacity: 0;
    width: 100%;
    max-width: 460px;
}

.life-insurance-calc-offer-info.info {
    background: #fff;
    float: left;
    opacity: 0.9;
    margin: 40px 0px 0px 0px;
    padding: 20px;
    width: 50%;
}

.life-insurance-calc-offer-info.lead-box {
    background: transparent;
    float: right;
    opacity: 1;
    margin: 20px 0px 0px 0px;
}

.life-insurance-calc-offer-info-price {
    margin-top: 0 !important;
    margin-bottom:0 !important;
}

.life-insurance-calc-offer-info h1 {
    padding-left: 0 !important;
    margin-bottom: 5px;
    line-height: 33px !important;
}

.kzo-animated .std-btn, .kzo-animated .choose  {
    cursor: pointer;
}

.life-insurance-calc-ctrl {
    position: absolute;
    bottom: 0;
    left: 5%;
    z-index: 11;
    padding-bottom: 18px;
}

.life-insurance-calc-ctrl .std-btn + .std-btn {
    margin-left: 10px;
}

.kzo-animated .breadcrumb-container {
    padding: 0;
}

.kzo-animated .box-featured {
    transform: scale(1.05);
}

.kzo-animated .box-plain .box .titles h3 {
    font-weight: bold;
    color: #04b675;
    margin-top: 0;
}

.kzo-animated .file-pdf{
    left:50%;
    margin-left:-61px;
    position:relative;
    z-index:1;
}

.kzo-animated .small-error-message{
    color: red;
    font-size: 82%;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    margin-top: 5px;
    display: block;
    padding-bottom: 10px;
}

.kzo-animated .start-calculator-msg{
    position: absolute;
    left: 50%;
    top: 80%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    transition: opacity .2s ease .2s;
    visibility: hidden;
}

.kzo-animated .start-calculator-msg.show {
    opacity: 1;
    z-index: 0;
    visibility: visible;
}

.kzo-animated .start-calculator-msg-arrow {
    fill: #ec6502;
    height: 36px;
    width: 100%;
}

.kzo-animated .start-calculator-msg-holder {
    background-color: #ec6502;
    border-radius: 3px;
    padding: 6px 10px 15px;
    position: relative;
    color: #fff;
    width: 100%;
    margin-top: 5px;
}

.kzo-animated .start-calculator-msg-title {
    font-size: 16px;
    font-family: StoneSansITCPro-Medium,arial,sans-serif;
}

.kzo-animated .start-calculator-msg p {
    margin: 0;
    padding-bottom: 0;
}

.kzo-animated .start-calculator-msg-holder:before {
    content: '';
    display: block;
    background-color: #ec6502;
    height: 11px;
    width: 27px;
    -ms-flex-order: 1;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.life-insurance-type-icon-holder{
    background-color: #e6e6e6;
    position: relative;
    overflow: hidden;
    min-height: 75px;
    margin-bottom: 24px;
    text-align: center;
}
.life-insurance-type-icon-holder:before {
    content: '';
    background: #fff;
    border-radius: 100%;
    z-index: 0;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 140px;
    min-height: 140px;
}

#do-i-need-insurance-wrapper p{
    margin: 0px;
    margin-bottom: 10px;
    padding: 0px;
}

.life-insurance-type-icon {
    position: relative;
    z-index: 1;
}

.life-insurance-type-icon-partner{
    width: 51px;
    height: 66px;
}

.life-insurance-type-icon-partner-children {
    width: 97px;
    height: 66px;
}

.life-insurance-type-icon-support-others {
    width: 76px;
    height: 66px;
}

#do-i-need-insurance-wrapper .breakpoint .inner{
    margin-top: -17px!important;
}

#do-i-need-insurance-wrapper .breakpoint .inner span{
    font-size: 24px!important;
}

#offer-boxes-wrapper .box-plain .box .content #content-inner {
    min-height: 230px;
}


@media (max-width: 800px) {
    .life-insurance-calc-offer-info.info {
        width: 100%;
    }
    .life-insurance-calc-offer-info.lead-box {
        width: 100%;
    }

    .kzo-animated .lower-section {
        background-color: #fff;
    }

    .life-insurance-step:last-of-type .life-insurance-step-num:before {
        content: '';
        display: block;
        position: absolute;
        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20%20width%3D%27100%27%20height%3D%27100%27%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%22-244%20248%2010%2010.1%22%20%3E%3Ccircle%20fill%3D%22%23FFFFFF%22%20cx%3D%22-241.5%22%20cy%3D%22250.5%22%20r%3D%222.5%22/%3E%3C/svg%3E');;
        background-size: 10px;
        width: 5px;
        height: 10000%;
        left: 50%;
        bottom: 36px;
        -webkit-transform: translateY(0) translateZ(-10px) translateX(-50%);
        transform: translateY(0) translateZ(-10px) translateX(-50%);
    }
    .kzo-animated .breadcrumb-container{
        margin-top:10px;
    }
}








