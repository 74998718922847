@font-face {
    font-family: 'StoneSansITCPro-Medium';
    font-display: auto;
    src: url('/assets/fonts/webfonts/30E285_0_0.eot');
    src: url('/assets/fonts/StoneSansITCPro-Medium/webfonts/30E285_0_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/StoneSansITCPro-Medium/webfonts/30E285_0_0.woff2') format('woff2'), url('/assets/fonts/StoneSansITCPro-Medium/webfonts/30E285_0_0.woff') format('woff'), url('/assets/fonts/StoneSansITCPro-Medium/webfonts/30E285_0_0.ttf') format('truetype');
}

@font-face {
    font-family: 'StoneSansITCPro-SemiBold';
    font-display: auto;
    src: url('/assets/fonts/StoneSansITCPro-SemiBold/webfonts/2D3575_0_0.eot');
    src: url('/assets/fonts/StoneSansITCPro-SemiBold/webfonts/2D3575_0_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/StoneSansITCPro-SemiBold/webfonts/2D3575_0_0.woff2') format('woff2'), url('/assets/fonts/StoneSansITCPro-SemiBold/webfonts/2D3575_0_0.woff') format('woff'), url('/assets/fonts/StoneSansITCPro-SemiBold/webfonts/2D3575_0_0.ttf') format('truetype');
}

/* predefs */
.main-color {
    background-color: #005ca9;
}

.main-color-text {
    color: #005ca9;
}

.primary-color {
    color: #005ca9;
    background-color: #005ca9;
}

.light-blue {
    background-color: #7fadd4;
}

.green {
    background-color: #04b675 !important;
}

.orange {
    background-color: #f7a600 !important;
}

.gray {
    background-color: gray;
}

.clearfix:after {
    content: " ";
    display: table;
    clear: both;
}

.block {
    display: block;
    float: left;
    width: 100%;
}

.relative {
    position: relative;
}

body {
    color: #282b34;
    font-family: sans-serif;
    font-size: 14px;
    background-color: #e1e1e1;
    padding: 0 !important;
}

h1, h2, h3, h4, h5 {
    font-family: StoneSansITCPro-Medium;
    font-weight: normal;
    font-style: normal;
}

div {
    font-family: sans-serif;
}

a {
    font-family: sans-serif;
    outline: none;
}

a:hover {
    outline: none !important;
}

a:focus {
    outline: none !important;
    -moz-outline-style: none;
    text-decoration: none !important;
}

a:active {
    outline: none !important;
    text-decoration: none !important;
}

input::-moz-focus-inner {
    border: 0;
}

input.has-error {
    box-shadow: 0 0 5px red;
    border: 1px solid red !important;
}

input.has-success {
    box-shadow: 0 0 5px #04b675;
    border: 1px solid #04b675 !important;
}

img {
    outline: none !important;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #b2b2b2;
    margin: 0px;
    padding: 0px;
}

hr.bar {
    width: 200px;
    margin: 10px 0px;
}

.no-padd {
    padding: 0 !important;
}

.no-lpadd {
    padding-left: 0 !important;
}

.no-rpadd {
    padding-right: 0 !important;
}

.no-tpadd {
    padding-top: 0 !important;
}

.no-bpadd {
    padding-bottom: 0 !important;
}

.no-vmargin {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.no-tmargin {
    margin-top: 0 !important;
}

.no-bmargin {
    margin-bottom: 0 !important;
}

.no-lmargin {
    margin-left: 0 !important;
}

.no-rmargin {
    margin-right: 0 !important;
}

.lmar-5 {
    margin-left: 5px !important;
}

.lmar-10 {
    margin-left: 10px !important;
}

.rmar-5 {
    margin-right: 5px !important;
}

.tmar-25 {
    margin-top: 25px;
}

.tpad-20 {
    padding-top: 20px !important;
}

.tpad-30 {
    padding-top: 30px !important;
}

.tpad-50 {
    padding-top: 50px !important;
}

.bpad-30 {
    padding-bottom: 30px !important;
}

.uppercase {
    text-transform: uppercase;
}

.z-index-negative {
    z-index: -1 !important;
}

.z-index-null {
    position: relative;
    z-index: 0 !important;
}

.no-width-change {
    width: auto !important;
}

.sprite {
    background-image: url('/../../assets/images/frontend/sprite.png');
    background-repeat: no-repeat;
}

.col-md-15 {
    width: 12.5%;
}

.col-md-45 {
    width: 37.5%;
}

.col-md-offset-45 {
    margin-left: 37.5%;
}

.size25 {
    width: 25px;
    height: 25px;
}

.size35 {
    width: 35px;
    height: 35px;
}

.size60 {
    width: 60px;
    height: 60px;
}

.ajax-loader {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url('/../../assets/images/frontend/ajax-loader.gif') no-repeat center center;
}

.damage-reports-next-loader {
    position: relative !important;
    margin-top: -2px !important;
    margin-right: 7px !important;
}

.vertical-align-top {
    vertical-align: top !important;
}

.info-holder {
    display: inline-block;
    width: 18px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: -892px -47px;
    cursor: pointer;
    vertical-align: middle;
}

.form .info-holder {
    margin-left: 10px;
}

.form .info-holder.no-space {
    margin-left: 0;
}

.transition {
    -webkit-transition: all 200ms ease-out 0ms;
    -moz-transition: all 200ms ease-out 0ms;
    -o-transition: all 200ms ease-out 0ms;
    transition: all 200ms ease-out 0ms;
}

.std-btn {
    font-family: StoneSansITCPro-Medium, sans-serif;
    color: #fff;
    font-size: 16px;
    float: left;
    padding: 8px 20px;
}

.std-btn.disabled {
    opacity: 0.3;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    filter: alpha(opacity=30);
    cursor: not-allowed;
}

.std-btn:hover {
    color: #fff;
    opacity: 0.8;
    -webkit-opacity: 0.8;
    -moz-opacity: 0.8;
    filter: alpha(opacity=80);
    text-decoration: none;
}

.std-btn:focus {
    color: #fff;
}

.btn-file {
    font-family: StoneSansITCPro-Medium, sans-serif;
    color: #fff;
}

.btn-file:hover {
    color: #fff;
    opacity: 0.8;
    -webkit-opacity: 0.8;
    -moz-opacity: 0.8;
    filter: alpha(opacity=80);
    text-decoration: none;
}

#location-details-form .input-group[class*=col-] {
    float: left;
    padding-left: 15px;
    padding-right: 15px;
}

.std-link-i {
    font-family: Arial, sans-serif;
    float: left;
    color: #282b34;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
}

.std-link-i.arrow .fa {
    float: left;
    font-size: 16px;
    font-weight: bold;
    padding-top: 3px;
    margin-right: 7px;
}

.std-link-i:hover {
    color: #282b34;
}

.container-fluid {
    background-color: #fff;
    max-width: 1600px;
    padding: 0;
}

.container {
    width: 100%;
    padding: 0;
}

.inner-section {
    max-width: 1600px;
    width: 90%;
}

#overlay {
    display: none;
    position: fixed;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.98);
    background-size: 200px;
    z-index: 999999999;
}

#overlay-content {
    display: none;
    position: fixed;
    padding: 0;
    padding-top: 100px;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    background-size: 200px;
    z-index: 999999999;
    -webkit-transition: all 200ms ease-out 0ms;
    -moz-transition: all 200ms ease-out 0ms;
    -o-transition: all 200ms ease-out 0ms;
    transition: all 200ms ease-out 0ms;
}

.overlay-popup-banner {
    cursor: pointer;
    max-width: 100%;
}

.overlay-video-container {
    display: block;
    width: 100%;
    max-width: 990px;
    background-color: #fff;
    padding: 10px;
    padding-bottom: 20px;
    border-radius: 3px;
}

.overlay-video-title {
    display: block;
    color: black;
    font-size: 20px;
    font-weight: bold;
    padding-top: 10px;
    margin-bottom: 15px;
}

.fixed {
    position: fixed;
    z-index: 9999;
}

.loading {
    background-position: center center;
    background-repeat: no-repeat;
}

#to-top {
    display: none;
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 46px;
    height: 43px;
    cursor: pointer;
    -webkit-transition: all 200ms ease-out 0ms;
    -moz-transition: all 200ms ease-out 0ms;
    -o-transition: all 200ms ease-out 0ms;
    transition: all 200ms ease-out 0ms;
    opacity: 0.6;
    -webkit-opacity: 0.6;
    -moz-opacity: 0.6;
    filter: alpha(opacity=60);
    z-index: 9999;
}

#to-top:hover {
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity=100);
}

/* HEADER STYLES */
.top-links {
    min-height: 30px;
    margin-bottom: 2px;
}

.top-links ul {
    display: block;
    float: left;
    width: 50%;
    margin: 0;
    padding-left: 6%;
}

.top-links ul li {
    display: inline;
    margin-right: 2px;
}

.top-links ul li a {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 28px;
    display: block;
    float: left;
    width: auto;
    color: #fff;
    padding: 1px 22px;
    margin-right: 2px;
    background-color: #7fadd4;
}

.top-links ul li a:hover {
    text-decoration: none;
    background-color: #005ca9;
}

.top-links ul li a.selected {
    background-color: #005ca9;
}

/* language change */
.top-links ul.languages {
    margin: 0;
    padding: 0;
    padding-right: 6%;
}

.top-links ul.languages li {
    float: right;
    margin-right: 0;
}

/* logo container */
.logo-container {
    padding-left: 50%;
    height: 0;
}

.logo-container #logo {
    width: 108px;
    height: 102px;
    margin-left: -54px;
    margin-top: -25px;
    background: #fff url('/../../assets/images/frontend/logo-uniqa.jpg') no-repeat center;
    border: 1px solid #ebebeb;
    position: absolute;
    z-index: 10;
}

.logo-container .mobile-logo {
    width: 108px;
    height: 102px;
    margin-left: -54px;
    margin-top: -25px;
    background: url('/../../assets/images/frontend/logo-uniqa-white.png') no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: 10;
}

/* main header/menu styles */
.header-container {
    background-color: #005ca9;
    min-height: 52px;
    margin-bottom: 2px;
}

/* navigation */
nav {
    position: relative;
    text-align: center;
}

/* prime */
ul.primary {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 21;
}

ul.primary.main-left {
    margin-left: 12%;
}

ul.primary.main-right {
    margin-left: 20%;
}

ul.primary li {
    display: inline;
    float: left;
}

ul.primary li a {
    font-family: StoneSansITCPro-Medium, sans-serif;
    color: #fff;
    display: block;
    padding: 16px 10px;
}

ul.primary li:last-child a {
    border-right: none;
}

ul.primary li a:hover {
    color: #000;
    text-decoration: none;
}

ul.primary li a.buy-online {
    background: #04b675;
}

ul.primary li a.buy-online:hover {
    background: #fff;
}

/* subs */
ul.submenu {
    position: absolute;
    z-index: 2;
    display: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-right: 1px solid #fff;
    min-height: 60px;
    padding: 25px 50px 15px 5px;
    background-color: #fff;
    min-width: 240px;
}

ul.submenu.large {
    width: 100%;
    max-width: 750px;
    min-height: 150px;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
}

ul.submenu li {
    float: none;
    margin: 0;
}

ul.submenu li a {
    font-size: 15px;
    line-height: 24px;
    padding: 4px 0px 5px 12px;
    text-align: left;
    background: transparent;
}

ul.submenu.large li a {
    font-size: 16px;
}

ul.submenu li a .fa {
    font-weight: bold;
    margin-right: 6px;
}

ul.submenu li:last-child a {
    border-bottom: none;
}

ul.submenu li a:hover {
}

/* sub display*/
ul.primary li:hover ul {
    display: block;
}

/* keeps the tab background white */
ul.primary li:hover a {
    background: #fff;
    color: #005ca9;
    text-shadow: none;
}

ul.primary li:hover ul.submenu li a {
    background: transparent;
}

ul.primary li:hover ul.submenu li > a:hover {
    text-decoration: underline;
}

/* third level primary menu hide items */
ul.primary > li > ul > ul {
    display: none !important;
}

/*  search */
.search-container {
    padding-top: 14px;
    padding-right: 27%;
    width: 100%;
}

.search-container input {
    width: 100%;
    border: none;
    height: 25px;
    padding: 8px 30px 8px 10px;
}

.search-container #search-submit {
    display: block;
    float: right;
    position: relative;
    width: 25px;
    height: 25px;
    padding-top: 2px;
    padding-left: 6px;
    margin-top: -25px;
    background-position: -350px -16px;
}

/* mobile navigation */
#show-mobile-menu {
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 24px;
    padding: 10px;
    outline: none;
}

.body-mobile-menu {
    background-color: #53555c;
    margin-left: -85%;
    width: 100%;
    -webkit-transition: all 200ms ease-out 0ms;
    -moz-transition: all 200ms ease-out 0ms;
    -o-transition: all 200ms ease-out 0ms;
    transition: all 200ms ease-out 0ms;
}

.body-mobile-menu-hide {
    margin-left: 0;
}

ul.mobile-nav {
    display: none;
    top: 0;
    left: 100%;
    width: 0;
    overflow: hidden;
    position: absolute;
    -webkit-transition: left 200ms ease-out 0ms;
    -moz-transition: left 200ms ease-out 0ms;
    -o-transition: left 200ms ease-out 0ms;
    transition: left 200ms ease-out 0ms;
}

ul.mobile-nav.show {
    display: block;
    left: 15%;
    width: 85%;
    z-index: 999999;
}

ul.mobile-nav .logo-container {
    height: 100px;
    padding-top: 23px;
    padding-bottom: 8px;
}

ul.mobile-nav i {
    float: right;
    margin-top: -5px;
}

ul.mobile-nav i.size25.plus {
    background-position: -1470px -41px;
}

ul.mobile-nav i.size25.plus.minus {
    background-position: -1435px -41px;
}

ul.mobile-nav li i.fa {
    font-size: 28px;
    margin-right: 3px;
}

ul.mobile-nav li {
    border-bottom: 1px solid #fff;
}

ul.mobile-nav li:last-child {
    border: none;
}

ul.mobile-nav li a {
    color: #fff;
    display: block;
    font-family: StoneSansITCPro-Medium, sans-serif;
    font-size: 1em;
    font-style: normal;
    line-height: 22px;
    padding: 14px 20px;
    text-decoration: none;
}

ul.mobile-nav li .sub {
    display: none;
    overflow: hidden;
}

ul.mobile-nav li .sub.show {
    height: auto;
    overflow: hidden;
}

ul.mobile-nav li .sub li {
    background: #7fadd4;
    color: #fff;
    line-height: 35px;
    padding: 0px 20px;
    position: relative;
}

ul.mobile-nav li .sub li:last-child {
    border: none;
}

ul.mobile-nav li .sub li a {
    background: #7fadd4;
    color: #fff;
    line-height: 22px;
    padding-left: 0;
    padding-right: 0;
    position: relative;
}

ul.mobile-nav li form {
    margin: 0;
}

ul.mobile-nav li .search-container {
    padding: 20px;
    max-width: 100%;
}

ul.mobile-nav li .search-container input {
    height: auto;
    padding: 10px 30px 10px 12px;
}

ul.mobile-nav li .search-container #mobile-search-submit {
    display: block;
    float: right;
    position: relative;
    width: 25px;
    height: 25px;
    padding-top: 2px;
    padding-left: 7px;
    margin-top: -32px;
    background-position: -350px -16px;
}

/* header carousel/slider styles */
.main-slider-container {
    width: 100%;
    height: 400px;
    overflow: hidden;
}

#main-slider .slide {
    width: 100%;
    min-height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
}

#main-slider .slide .info-box {
    float: left;
    background: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    filter: alpha(opacity=90);
    opacity: 0.9;
    margin: 70px 0px 0px 120px;
    padding: 20px;
    width: 30%;
}

#main-slider .slide .info-box h2 {
    font-size: 32px;
    line-height: 36px;
    margin-top: 0;
}

#main-slider .slide .info-box a {
    font-family: StoneSansITCPro-Medium, sans-serif;
    color: #fff;
    font-size: 16px;
    float: left;
    padding: 8px 20px;
}

#main-slider .slide .info-box a:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    opacity: 0.8;
    text-decoration: none;
}

#main-slider .owl-controls .owl-buttons div {
    width: 70px;
    height: 70px;
    top: -255px;
    position: relative;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    background: url('/../../assets/images/frontend/sprite.png');
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

#main-slider .owl-controls .owl-buttons .owl-prev {
    float: left;
    margin-left: 10px;
    background-position: 0 -136px;
}

#main-slider .owl-controls .owl-buttons .owl-prev:hover {
    background-position: -560px -137px;
}

#main-slider .owl-controls .owl-buttons .owl-next {
    float: right;
    margin-right: 10px;
    background-position: -70px -136px
}

#main-slider .owl-controls .owl-buttons .owl-next:hover {
    background-position: -630px -136px;
}

#main-slider .owl-pagination {
    margin-top: -27px;
}

#main-slider .owl-pagination .owl-page {
    position: relative;
    z-index: 1;
}

#main-slider .owl-pagination .owl-page span {
    width: 40px;
    height: 12px;
    margin: 5px 3px;
    background-color: #d2d2d2;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity=100);
}

#main-slider .owl-pagination .owl-page span:hover {
    background-color: #999;
}

#main-slider .owl-pagination .owl-page.active span {
    background-color: #999;
}

/* breadcrumbs */
.breadcrumb-container {
    display: block;
    float: left;
    width: 100%;
    padding: 32px 0 14px 0;
}

.breadcrumb-container .inner-section {
    width: 90%;
    margin: 0 auto;
    padding: 0 15px;
}

.breadcrumb-container .breadcrumb {
    padding: 0;
    margin: 0;
    height: auto;
    background-color: transparent;
    list-style: none;
}

.breadcrumb-container .breadcrumb > li > a {
    color: #999;
    font-size: 13px;
    font-style: italic;
    margin-right: 5px;
}

.breadcrumb-container .breadcrumb > li:before {
    content: "";
    padding: 0;
    padding-left: 4px;
}

.breadcrumb-container .breadcrumb > li .fa {
    color: #999;
    font-size: 13px;
}

/* homepage styles */
#homepage .teaser {
    display: block;
    float: left;
    width: 100%;
    margin: 40px 0;
}

#homepage .gray {
    display: block;
    float: left;
    width: 100%;
    background-color: #f4f4f4;
    padding: 40px 0;
}

/* lead container */
.lead-container {
    margin-bottom: 40px;
}

.lead-container .clearfix {
    margin: 0 !important;
}

.lead-container .box {
    color: #fff;
    margin-bottom: 2px !important;
    background-color: #7fadd4 !important;
}

/* lead contact box */
.lead-container .contact-box {
    float: left;
    width: 100%;
    padding: 20px;
}

.lead-container h3 {
    font-size: 20px !important;
    line-height: 24px !important;
    margin: 0;
}

.lead-container .contact-box .std-btn.border {
    border: 1px solid #fff;
    padding: 10px 30px 5px 20px;
    margin-top: 10px;
    width: auto !important;
}

.lead-container .contact-box .std-btn.border:active {
    color: #fff;
    text-decoration: none;
}

.lead-container .contact-box .std-btn.border:focus {
    color: #fff;
    text-decoration: none;
}

.lead-container .contact-box a.border span {
    background-position: -677px -50px;
}

.lead-container .box a.i-link {
    color: #fff;
    font-family: Arial, sans-serif;
    font-weight: bold;
}

.lead-container .box a.i-link .fa {
    font-size: 16px;
    font-weight: bold;
    margin-right: 7px;
}

.lead-container .navigation p {
    float: left;
    width: 50%;
    padding: 0 2px 0 0 !important;
}

.lead-container .navigation p.right {
    padding: 0 0 0 2px !important;
}

.lead-container .navigation a {
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 14px;
    padding: 12px 10px;
    background-color: #7fadd4;
    position: relative;
}

.lead-container .navigation a.selected {
    background-color: #04b675;
}

.lead-container .navigation a.selected i {
    display: block;
    background-position: -782px -28px;
    height: 6px;
    left: 50%;
    margin-left: -6px;
    top: -8px;
    width: 12px;
    position: absolute;
}

.lead-container .navigation a:focus {
    color: #fff;
    text-decoration: none;
}

/* lead */
#lead {
    padding: 20px;
}

#lead-slide-2 {
    display: none;
}

#lead h3 {
    margin: 2px 0 5px 0;
}

#lead p {
    padding-top: 5px;
}

#lead .form-container input, #lead .form-container select {
    width: 100%;
    height: 35px;
    line-height: 25px;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #282B34;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    outline: none;
    padding: 0px 10px;
    margin-bottom: 10px;
}



#lead .form-container input.error {
    border: 1px solid red !important;
}

#lead .form-container input[type=submit] {
    width: 100%;
    height: auto;
    text-align: center;
    font-family: StoneSansITCPro-SemiBold, sans-serif;
    color: #fff;
    font-size: 16px;
    float: left;
    padding: 8px 20px;
    margin: 0;
    border: none;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    background-color: #005ca9;
}

/* search page */
.search-page {
    width: 100%;
    height: 75px;
    background-color: #f4f4f4;
    display: table;
    vertical-align: middle;
    line-height: 75px;
    position: relative;
}

.search-page input {
    height: 40px;
    border: 1px solid #e1e1e1;
    vertical-align: middle;
    display: inline-block;
    width: 79%;
    margin: 0 2%;
    outline: none;
    padding: 0px 10px;
    line-height: 35px;
}

.search-page .search-form-submit {
    border: 0px;
    color: #fff;
    display: inline-block;
    font-family: StoneSansITCPro-SemiBold, sans-serif;
    font-style: normal;
    font-size: 1.143em;
    height: 38px;
    line-height: 38px;
    opacity: 1;
    padding: 0px 20px;
    text-decoration: none;
    text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
    -moz-text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
    -webkit-text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    padding-left: 35px;
    width: 15%;
    margin-right: 1%;
    text-align: center;
    background-color: #04b675;
}

.search-result {
    margin-top: 20px;
}

.search-result .listing a {
    color: #005CA9 !important;
    text-decoration: underline;
    font-size: 17px;
    font-family: "StoneSansITCPro-Medium";
    font-style: normal;
}

.search-result .pageLink {
    clear: both;
    margin-bottom: 20px;
    color: #04B675;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 20px;
}

.search-question-box {
    color: #000;
    margin-top: 20px;
    text-align: left;
    border: 1px solid #e1e1e1;
    padding: 20px;
    padding-bottom: 25px;
    margin-bottom: 20px;
}

.search-question-box h2 {
    margin: 0;
    padding: 0 !important;
    padding-top: 2px !important;
    padding-bottom: 20px !important;
}

.search-question-box a {
    color: #282b34;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    font-style: italic;
    text-decoration: underline;
}

.search-question-box p {
    padding-top: 0 !important;
}

.search-question-box .arrow {
    display: inline-block;
    font-weight: bold;
    height: 16px;
    line-height: 16px;
    padding: 9px 0px 8px 12px;
    position: relative;
    text-decoration: none;
}

.bk-icon-left {
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

/* homepage boxes */
#homepage .box-container {
    position: relative;
    width: 100%;
}

#homepage .box {
    display: block;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 30px;
}

#homepage .box-container .box.small {
    margin-bottom: 11px;
}

#homepage .box-container .image-background {
    position: absolute;
    display: block;
    width: 100%;
    min-height: 130px;
    background-repeat: no-repeat;
    background-size: cover;
}

#homepage .box-container .small.image-background {
    position: relative;
    float: left;
    min-height: 112px;
}

#homepage .box.small .title-container {
    margin-top: 64px;
}

#homepage .box a {
    width: 100%;
    min-height: 35px;
}

#homepage .box a:hover {
    text-decoration: none;
}

#homepage .box a .icon {
    position: absolute;
    width: 35px;
    height: 35px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

#homepage .box a h5 {
    color: #282b34;
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
    height: 35px;
    max-height: 56px;
    overflow: hidden;
    padding: 10px 30px 5px 50px;
    margin: 0;
    background-color: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
    filter: alpha(opacity=85);
    opacity: 0.85;
    position: absolute;
    height: auto;
    width: 100%;
}

#homepage .box a h5.no-icon {
    padding-left: 20px;
}

#homepage .box a h5 .fa {
    float: right;
    font-size: 18px;
    font-weight: bold;
    padding-top: 2px;
    position: absolute;
    top: 8px;
    right: 10px;
}

#homepage .box a h5 .fa.precious {
    right: 16px;
}

#homepage .box .content {
    display: block;
    float: left;
    width: 100%;
    margin-top: 130px;
    margin-bottom: 55px;
    padding: 10px 20px;
}

/* homepage orange box with full background image */
#homepage .box-image-orange {
    min-height: 175px;
}

#homepage .box-image-orange .image-background {
    min-height: 235px;
}

/* homepage link button */
#homepage .box .more {
    width: 100%;
    text-align: center;
    position: absolute;
    padding: 0 10%;
}

#homepage .box .more.bottom {
    bottom: 11px;
}

#homepage .box .more a {
    display: block;
    color: #fff;
    font-family: StoneSansITCPro-Medium, sans-serif;
    font-size: 16px;
    padding: 8px 6px 7px 6px;
    text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
    -moz-text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
    -webkit-text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
}

#homepage .box .more a:hover {
    text-decoration: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    opacity: 0.8;
}

/* homepage news box styles */
#homepage .news-box h3 {
    font-family: StoneSansITCPro-SemiBold, sans-serif;
    font-size: 20px;
    margin: 0;
    margin-bottom: 11px;
}

#homepage .news-box .item {
    margin-bottom: 5px;
}

#homepage .news-box .item .date {
    color: #999;
    font-size: 13px;
    font-style: italic;
}

#homepage .news-box .item a {
    font-family: Arial, sans-serif;
    color: #282b34;
    font-size: 14px;
    line-height: 20px;
}

#homepage .news-box a.list-all {
    font-family: Arial, sans-serif;
    float: left;
    color: #282b34;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    margin-top: 23px;
}

#homepage .news-box a.list-all .fa {
    font-size: 16px;
    font-weight: bold;
    margin-right: 7px;
}

/* homepage you have question? box */
#homepage .questions-box {
    color: #fff;
    font-size: 15px;
    min-height: 235px;
    padding: 20px;
}

#homepage .questions-box a {
    color: #fff;
}

#homepage .questions-box h4 {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    margin-bottom: 20px;
}

#homepage .questions-box .contact-center {
    font-family: Arial, sans-serif;
    font-style: italic;
    text-decoration: underline;
}

#homepage .questions-box p {
    margin-top: 10px;
}

#homepage .questions-box p .fa {
    font-size: 16px;
    margin-right: 2px;
}

#homepage .questions-box p .fa-mobile {
    font-size: 18px;
    margin-left: 8px;
    margin-right: 3px;
}

#homepage .questions-box .info-mail {
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-style: italic;
}

#homepage .questions-box .info-mail .fa {
    font-size: 16px;
    font-weight: bold;
    margin-right: 7px;
}

#homepage .questions-box .damage-report {
    font-family: StoneSansITCPro-Medium, sans-serif;
    font-size: 16px;
    display: block;
    margin-top: 14px;
    padding: 10px;
}

#homepage .questions-box .damage-report:hover {
    text-decoration: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    opacity: 0.8;
}

#homepage .questions-box .damage-report span {
    float: left;
    width: 35px;
    height: 22px;
    background-position: -105px -48px;
}

/* homepage products section styles */
.grid-products h2 {
    font-size: 32px;
    font-weight: normal;
    line-height: 36px;
    margin-top: 0;
    text-align: center;
}

.grid-products hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #b2b2b2;
    padding: 0px;
    margin: 0px auto 20px;
    width: 130px;
}

.grid-products .inner-section {
    width: 100%;
    padding: 0 10%;
}

.grid-products .inner-section .group {
    padding-top: 30px;
    padding-bottom: 50px;
    max-width: 900px;
    margin: 0 auto;
}

.grid-products .icon {
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    background-repeat: no-repeat;
}

.grid-products .item {
    margin-left: 90px;
    padding-right: 0; /* set 90px when there are more than one item */
}

.grid-products .item .title {
    font-family: StoneSansITCPro-SemiBold, sans-serif;
    font-size: 17px;
    color: #282b34;
    line-height: 24px;
}

.grid-products .item p {
    font-size: 14px;
    line-height: 20px;
    padding-top: 5px;
}

/* homepage social boxes styles */
#homepage .social {
    display: block;
    float: left;
    width: 100%;
    padding: 30px 0 20px 0;
    margin-top: -30px;
    background-color: #fff;
}

#homepage .social .inner-section {
    margin: 0 auto;
}

.social h4 {
    color: #282b34;
    font-family: StoneSansITCPro-SemiBold, sans-serif;
    font-size: 17px;
    line-height: 17px;
    padding: 10px 0;
}

.social h4.icon {
    padding-left: 40px;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 32px;
}

.social h4 .sprite {
    float: left;
    width: 32px;
    height: 32px;
}

.social h4 .newsletter {
    background-position: -210px -50px;
}

.social h4 .contact {
    background-position: -245px -50px;
}

.social p {
    padding-bottom: 8px;
    min-height: 70px;
}

.social .std-link-i {
    margin-top: 5px;
}

.social .newsletter-container {
    max-width: 280px;
}

.social .newsletter-container input {
    height: 38px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #282B34;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    outline: none;
    padding: 0px 10px;
    padding-right: 47px;
}

/*newsletter styles*/
.newsletter-container .has-error {
    box-shadow: 0 0 5px red;
    border: 1px solid red !important;
}

.newsletter-container .has-success {
    box-shadow: 0 0 5px green;
    border: 1px solid green !important;
}

.newsletter-error {
    list-style: none;
    color: red;
    padding-top: 5px;
}

.social .newsletter-container #newsletter-submit {
    background-position: -279px -43px;
    width: 36px;
    height: 34px;
    position: relative;
    float: right;
    right: 2px;
    margin-top: -36px;
}

.social .contact-container a {
    color: #282b34;
    font-style: italic;
    text-decoration: underline;
}

/* page stypes */
#page {
    display: block;
    float: left;
    width: 100%;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('/../../assets/images/frontend/page-background-default.png');
    min-height: 400px;
}

#page .inner-section {
    width: 90%;
    margin: 0 auto;
}

#page .content {
    width: 50%;
    padding-bottom: 30px;
    font-size: 14px;
    line-height: 22px;
    overflow: hidden;
}

#page .content img {
    max-width: 100%;
    height: auto;
}

#page h1 {
    font-size: 32px;
    line-height: 42px;
    padding-left: 42px;
}

#page h1 span {
    float: left;
    background-position: -630px -40px;
    width: 35px;
    height: 35px;
    margin-left: -44px;
}

#page h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    padding: 16px 0 10px 0;
}

#page p {
    padding-top: 10px;
}

#page .widgets {
    float: left;
    width: 101.6%;
    margin-left: -1.6%;
    padding-bottom: 30px;
}

#page .widgets > .break {
    width: 100%;
    display: block;
    float: left;
    height: 1px;
}

#page .widgets > div {
    padding-right: 0;
    padding-left: 1.6%;
}

#page .widgets .clearfix {
    margin-bottom: 40px;
}

#page .widgets .breakpoint {
    border-top: 1px solid #b2b2b2;
    margin-top: 20px;
    min-height: 50px;
}

#page .widgets .breakpoint .inner {
    display: block;
    margin-top: -10px;
}

#page .widgets .breakpoint span {
    color: #808080;
    background-color: #fff;
    font-size: 14px;
    font-family: sans-serif;
    text-align: center;
    padding: 4px 12px;
}

#page .box-container {
    position: relative;
    width: 100%;
    float:left;
}

#page .box {
    display: block;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 30px;
    background-color: #fff;
}

#page .box-container .image-background {
    position: absolute;
    display: block;
    width: 100%;
    min-height: 130px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
}

#page .box-container .sticker {
    display: block;
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
}

#page .box a {
    width: 100%;
    min-height: 35px;
}

.product-boxes-container.vertical .homepage-box a {
    display: block;
    margin-top: 65px;
}

#page .box a:hover {
    text-decoration: none;
}

#page .box a .icon {
    position: absolute;
    width: 35px;
    height: 35px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

#page .box a h5 {
    color: #282b34;
    font-size: 15px;
    font-weight: normal;
    line-height: 22px;
    height: 35px;
    max-height: 56px;
    overflow: hidden;
    padding: 9px 30px 6px 50px;
    margin: 0;
    background-color: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
    filter: alpha(opacity=85);
    opacity: 0.85;
    position: absolute;
    height: auto;
    width: 100%;
}

#page .box a h5.no-icon {
    padding-left: 20px !important;
}

#page .box h5.no-icon {
    padding-left: 20px !important;
}

#page .box a h5 .fa {
    float: right;
    font-size: 18px;
    font-weight: bold;
    padding-top: 2px;
    position: absolute;
    top: 8px;
    right: 10px;
}

#page .box a h5 .fa.precious {
    right: 16px;
}

#page .box .content {
    display: block;
    float: left;
    width: 100%;
    margin-top: 130px;
    margin-bottom: 55px;
    padding: 10px 20px;
    line-height: 22px;
}

#
#page .box .content.no-margin {
    margin-bottom: 0;
}

#page .box .content h1 {
    font-size: 25px;
    font-weight: normal;
    line-height: 34px;
    margin-top: 10px;
    padding: 0;
}

#page .box .content h2 {
    font-size: 21px;
    font-weight: normal;
    line-height: 28px;
    margin-top: 10px;
    padding: 0;
}

#page .box .content h3 {
    font-size: 17px;
    font-weight: normal;
    line-height: 24px;
    margin-top: 10px;
    padding: 0;
}

#page .box .content h4 {
    line-height: 24px;
}

#page .box .content ul {
    list-style: none;
    padding: 0;
    margin-top: 10px;
    margin-left: 5%;
    margin-right: 5%;
}

#page .box .content ul li {
    padding-left: 30px;
    margin-bottom: 7px;
}

#page .box .content ul li span {
    position: absolute;
    margin-left: -30px;
    margin-top: 4px;
    float: left;
    width: 30px;
    height: 16px;
    background-position: -375px -16px;
}

#page .box .more {
    width: 100%;
    text-align: center;
    position: absolute;
    padding: 0 10%;
}

#page .box .more.bottom {
    bottom: 15px;
}

#page .box .more a {
    display: block;
    color: #fff;
    font-family: StoneSansITCPro-Medium, sans-serif;
    font-size: 16px;
    padding: 6px;
    text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
    -moz-text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
    -webkit-text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
}

#page .box .more a:hover {
    text-decoration: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    opacity: 0.8;
}

#page .numeric-lists .container {
    padding-left: 47px;
    padding-bottom: 30px;
    padding-right: 20px;
}

#page .numeric-lists .container strong {
    margin-bottom: -12px;
    display: block;
}

#page .numeric-lists .container p {
    padding-top: 6px;
}

#page .numeric-lists .ordinal {
    position: absolute;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    width: 2em;
    margin-top: -5px;
    margin-left: -40px;
    padding: 4px;
    border-top: 2px solid #808080;
    color: #fff;
    background: #808080;
    font-family: Arial, sans-serif;
    text-align: center;
}

#page .numeric-lists ul {
    list-style-type: circle;
    padding-top: 10px;
}

#page .quoted table {
    width: 100% !important;
}

#page .quoted blockquote {
    color: #005eab;
    font-family: StoneSansITCPro-Medium, sans-serif;
    font-size: 24px;
    font-style: italic;
    line-height: 34px;
    border-left: none;
}

#page .widgets > .icons {
}

#page .widgets > .icons div {
    display: inline-block;
}

#page .widgets > .icons > div {
    padding: 0;
}

#page .widgets > .icons .image {
    position: absolute;
    width: 80px;
    overflow: hidden;
}

#page .widgets > .icons .image img {
    margin-right: 5px;
}

#page .widgets > .icons .image p {
    padding: 0;
}

#page .widgets > .icons .html {
    padding-left: 80px;
    min-height: 90px;
    padding-top: 14px;
}

#page .widgets > .icons .quoted {
    width: 100%;
    padding-top: 5px;
}

#page .widgets > .icons .quoted blockquote {
    padding-top: 0;
}

/* box styles */

/* plain box */
#page .box-plain .box {
    border: 1px solid #e1e1e1;
    background-color: #fff;
    float: left;
    padding: 20px;
}

#page .box-plain .box .titles h2 {
    font-size: 32px;
    line-height: 36px;
    font-weight: normal;
    padding: 0;
    margin-top: 0;
}

#page .box-plain .box .titles h4 {
    background-color: transparent;
    line-height: 23px;
}

.webshop .box-plain .box .titles h4 {
    background-color: transparent;
    line-height: 23px;
    min-height: 46px;
}

.webshop .box-plain .box .titles h3.dzo, .webshop .box-plain .box .titles h3.dlm {
    font-weight: bold;
    color: #04b675;
    margin-top: 0;
    font-size: 21px;
}

#page .box-plain .box .content {
    margin: 0;
    padding: 0;
}

#page .box-plain .box .content #content-inner {
    min-height: 270px;
}

#page .box-plain .content hr {
    border-top: 1px solid #e1e1e1;
    height: 15px;
}

#page .box-plain .content ul {
    margin: 0 10%;
    padding-top: 10px;
    padding-bottom: 6px;
}

#page .box-plain .content a {
    color: #282b34;
    font-style: italic;
    text-decoration: underline;
}

#page .box-plain .content a.file-pdf {
    background: url('/../../assets/images/frontend/pdf-icon.png') no-repeat center left;
    padding-left: 26px;
}

#page .box-plain .more.bottom {
    padding-top: 20px;
    padding-bottom: 6px;
    position: relative;
    float: left;
}

#page .box-plain .more a {
    display: initial;
    padding: 10px 20px;
}

/* primary box */
#page .box-primary .box {
    border: 1px solid #e1e1e1;
    position: relative;
    float: left;
    height: auto;
    min-height: 328px;
}

#page .box-primary .more.bottom {
    bottom: 25px;
}

#page .box-primary .more a {
    display: initial;
    padding: 10px 20px;
}

/* orange box with full background image */
#page .box-image-orange {
    min-height: 175px;
    background-position: center top;
}

#page .box-image-orange .image-background {
    min-height: 175px;
    background-color: center top;
}

/* youtube box */
#page .box-primary.box-youtube .box {
    overflow: hidden;
    min-height: auto;
}

#page .box-primary.box-youtube .box .box-header {
    height: 230px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

#page .box-primary.box-youtube.box-yt-lg .box .box-header {
    height: 340px;
}

#page .box-primary.box-youtube .box .image-background {
    min-height: 230px;
    background-position: center;
    -webkit-transition: all 200ms ease-out 0ms;
    -moz-transition: all 200ms ease-out 0ms;
    -o-transition: all 200ms ease-out 0ms;
    transition: all 200ms ease-out 0ms;
}

#page .box-primary.box-youtube.box-yt-lg .box .image-background {
    min-height: 340px;
}

#page .box-primary.box-youtube .box .play {
    min-height: 230px;
    background-position: center;
    background-image: url(/assets/images/frontend/icon-play.png);
    z-index: 1;
    position: relative;
    background-repeat: no-repeat;
    background-size: 66px;
    cursor: pointer;
    border: 10px solid #fff;
    -webkit-transition: all 200ms ease-out 0ms;
    -moz-transition: all 200ms ease-out 0ms;
    -o-transition: all 200ms ease-out 0ms;
    transition: all 200ms ease-out 0ms;
}

#page .box-primary.box-youtube.box-yt-lg .box .play {
    min-height: 340px;
}

#page .box-primary.box-youtube .box .play:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

#page .box-primary.box-youtube .box .play:hover ~ .image-background {
    transform: scale(1.1);
}

#page .box-primary.box-youtube .content {
    margin-top: 0;
    padding-top: 0;
    text-align: center;
}

.modal-youtube .modal-dialog {
    max-width: 1200px;
}

.modal-youtube .modal-header {
    border-bottom: none;
}

.modal-youtube .modal-body {
    padding: 0;
}

.modal-youtube .modal-footer {
    border-top: none;
    margin-top: -5px;
}

/* contact box. inherite primary box styles and override it */
#page .box-contact .box {
    min-height: 180px;
}

#page .box-contact .box h5 {
    color: #fff;
    background-color: #005ca9;
    font-size: 1.214em;
    font-weight: bold;
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity=100);
    max-height: 56px;
    overflow: hidden;
    padding: 11px 30px 6px 50px;
    margin: 0;
    position: absolute;
    height: 35px;
    width: 100%;
}

#page .box-contact .box .content {
    min-height: auto;
}

#page .box-contact .box .icon {
    position: absolute;
    width: 35px;
    height: 35px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

#page .box-contact .box .content {
    margin-top: 34px;
}

/* account type page boxes. extends and override primary box styles */
#page .account-type-lists-container {
    float: left;
    margin-left: -1.6%;
    width: 101.6%;
    margin-bottom: 15px;
}

#page .account-type-lists {
    padding: 0;
    padding-left: 1.6%;
}

#page .account-type-lists .box {
    border: none;
    position: initial;
    height: 130px;
    min-height: auto;
    margin-bottom: 20px;
}

#page .account-type-lists .item-container {
    float: left;
    width: 100%;
    margin-bottom: 25px;
    padding-left: 75px;
}

#page .account-type-lists .item-container a h3 {
    color: #282b34;
    font-size: 20px;
    line-height: 26px;
    margin: 0;
    margin-bottom: 5px;
}

#page .account-type-lists .item-container a:hover {
    text-decoration: none !important;
}

#page .account-type-lists .item-container p {
    padding: 0;
    line-height: 22px;
}

#page .account-type-lists .item-container a i {
    position: absolute;
    margin-left: -75px;
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    border: 2px solid #e1e1e1;
}

/* filters */
.filters {
    background-color: #f4f4f4;
    margin-bottom: 20px;
}

.filters h2 {
    font-weight: normal;
}

.filters ul li {
    padding: 0 20px;
    border-top: 2px solid #fff;
}

.filters.search {
    padding: 20px;
}

.filters .search-container {
    padding: 0;
    max-width: 100%;
}

.filters .search-container input {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 7px 10px;
    height: auto;
}

.filters .search-container #filters-search-submit {
    display: block;
    float: right;
    width: 25px;
    height: 25px;
    padding-top: 2px;
    background-position: -850px -16px;
    position: absolute;
    right: 40px;
    margin-top: -30px;
}

.filters.search .std-link-i {
    margin-top: 8px;
}

.filters.filter h2 {
    font-weight: normal !important;
    padding: 20px !important;
    padding-bottom: 15px !important;
    margin: 0;
}

.filters.filter ul {
    margin: 0;
}

.filters.filter ul li {
    padding-top: 9px;
    padding-bottom: 9px;
}

.filters.filter ul li a {
    color: #282b34;
}

.filters.filter ul li a:hover {
    font-style: italic;
}

/* product box */
.p-box {
    float: left;
    width: 100%;
    padding: 20px;
}

.p-box h3 {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
}

.p-box .std-btn.border {
    border: 1px solid #fff;
    padding: 10px 20px 5px 20px;
    margin-top: 10px;
}

.p-box.contact-box a.border span {
    background-position: -677px -50px;
}

/* news styles */
#page.news h1 {
    padding-left: 0;
}

.news .news-box {
    width: 33.33333%;
    padding: 0 0.8%;
    margin-bottom: 1.6%;
}

.news .news-box > div {
    background-color: #f9f9f9;
    height: 220px;
}

.news .news-box .image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    height: 120px;
}

.news .news-box a {
    font-family: Arial, sans-serif;
    color: #282b34;
    padding: 10px;
    font-size: 14px;
    line-height: 22px;
    max-height: 86px;
    overflow: hidden;
}

.news .news-box a span {
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
    color: #005ca9;
    width: 100%;
    padding: 3px 0;
}

.news .news-content {
    width: 100% !important;
    padding-bottom: 50px;
    padding-left: 0.8%;
}

.news .news-content p {
    font-size: 14px;
    line-height: 25px;
}

.news .news-content img {
    max-width: 100%;
}

.news .news-content h1 {
    line-height: 40px !important;
    margin-top: 0;
    margin-bottom: 14px;
}

.news .news-content h2 {
    line-height: 27px !important;
}

.news .news-content h3 {
    font-size: 17px;
    line-height: 25px;
}

.news .news-content h4 {
    line-height: 23px;
}

.news .news-content h5 {
    line-height: 21px;
}

.news .news-content h6 {
    line-height: 18px;
}

/* locations styles */
.locations-map {
    height: 480px;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 20px;
    overflow: hidden;
}

#map {
    height: 100% !important;
    width: 100% !important;
}

.locations-map-info-left {
    height: 100%;
    width: 300px;
    position: absolute;
    top: 0;
    left: -300px;
    background-color: #f5f5f5; /*display: none;*/
    transition: 0.7s;
}

.locations-map-info-left .header {
    background-color: #005ca9;
    position: absolute;
    width: 100%;
    padding-top: 10px;
}

.locations-map-info-left .arrow-left-close:before {
    content: '\2039';
    font-size: 3em;
    top: -12px;
    left: 10px;
    color: #fff;
    position: absolute;
    cursor: pointer;
}

.locations-map-info-left .header p {
    padding-left: 50px;
    color: #fff;
    padding-top: 0 !important;
}

.locations-map-info-left .body {
    overflow-y: auto;
    height: 100%;
    padding: 50px 20px 20px;
    box-sizing: border-box;
}

.locations-map-info-left .body label {
    color: #9e9e9e;
    font-size: 12px;
}

.locations-map-info-left .body p {
    line-height: 21px;
    padding-top: 0 !important;
}

.locations-map-info-left-show {
    left: 0;
    transition: 0.7s;
    z-index: 10000;
}


.locations-table {
    padding-top: 30px;
    padding-bottom: 50px;
}

table.dataTable thead th {
    text-align: center;
}

table.dataTable.display tbody tr.odd {
    background-color: #fff;
}

table.dataTable.display tbody tr:hover {
    background-color: #fff;
}

table.dataTable.display tbody td {
    border-left: 1px solid #ccc;
}

table.dataTable tbody td {
    padding: 5px 10px;
}

table.dataTable.display tbody td:last-child {
    border-right: 1px solid #ccc;
}

#data-table_filter {
    display: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background: none;
    padding: 7px 14px;
    background-color: #efefef;
    border: 1px solid grey;
    width: auto !important;
    font-size: 1em !important;
    margin-top: 0 !important;
    color: #282b34 !important;
}

/* flash messages box styles */
.flash-box {
    width: 30%;
    height: 350px;
    background: #fff;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    z-index: 9999999999;
}

.close-flash-box {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 120%;
    color: #fff;
    cursor: pointer;
}

.flash-box-header-info {
    background: #005CA9;
    width: 100%;
    height: 25%;
}

.flash-box-info-circle {
    position: absolute;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background: #005CA9;
    border: 3px solid #fff;
    left: 50%;
    transform: translateX(-50%);
    top: 14%;
}

.flash-box-info:before {
    content: "i";
    font-size: 60px;
    font-weight: bold;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    display: block;
    font-family: "Times New Roman", Times, serif;
    line-height: 1;
}

.flash-box-header-warning {
    background: #F7A600;
    width: 100%;
    height: 25%;
}

.flash-box-warning-circle {
    position: absolute;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background: #F7A600;
    border: 3px solid #fff;
    left: 50%;
    transform: translateX(-50%);
    top: 14%;
}

.flash-box-warning:before {
    content: "!";
    font-size: 60px;
    font-weight: bold;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    display: block;
    font-family: "Times New Roman", Times, serif;
    line-height: 1;
}

.flash-box-header-danger {
    background: #bb321f;
    width: 100%;
    height: 25%;
}

.flash-box-danger-circle {
    position: absolute;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background: #bb321f;
    border: 3px solid #fff;
    left: 50%;
    transform: translateX(-50%);
    top: 14%;
}

.flash-box-danger:before {
    content: '\26A0';
    font-size: 55px;
    font-weight: bold;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    display: block;
    font-family: "Times New Roman", Times, serif;
    line-height: 1;
}

.flash-box-body {
    height: 50%;
}

.flash-box-body p {
    color: #000;
    margin: 0 20px;
    font-size: 140%;
}

.flash-box-body .prettyradio label {
    font-size: 140% !important;
}

#poll-flash-box .flash-box {
    height: 400px;
}

#poll-flash-box .flash-box-body {
    height: auto;
}

#poll-flash-box a {
    color: #fff;
    text-decoration: none;
}

#poll-flash-box .flash-box-info-circle {
    border-radius: 0;
    width: auto;
    top: 10%;
}

#poll-flash-box .flash-box-info:before {
    content: url('/../../assets/images/frontend/logo-uniqa.jpg');
}

.std-btn-2 {
    background: #005eab;
    display: block;
    padding: 14px 0;
    border: 0;
    width: 100%;
    overflow: hidden;
    font: 1.25em/120% sans-serif;
    text-align: center;
    font-weight: 700;
    border-radius: 3px;
    cursor: pointer;
    text-transform: uppercase;
}

.std-btn-2:hover {
    opacity: 0.8;
    color: #FFFFFF;
    -webkit-transition: background 0.2s ease-out 0;
    -moz-transition: background 0.2s ease-out 0s;
    -o-transition: background 0.2s ease-out 0;
    transition: background 0.2s ease-out 0;
    text-decoration: none;
}

.flash-box-footer {
    background: #F4F4F4;
    height: 25%;
    position: relative;
    bottom: 0;
}

#poll-flash-box .flash-box-footer {
    bottom: -40px;
}

/* webshop styles */
.webshop {
    padding-bottom: 50px;
}

.webshop .ajax-loader {
    margin-left: 8px;
    margin-top: -10px;
    position: absolute;
}

.webshop .accepted-payment-img {
    float: none !important;
}

.webshop .form fieldset {
    display: block;
    float: left;
    width: 100%;
    padding: 25px 0;
}

.webshop .form .item {
    padding: 0 7%;
}

.webshop .form .item .form-group {
    margin-bottom: 10px;
}

.webshop #travel-number-of-days-container {
    display: none;
}

.webshop .form .item .prettycheckbox, .webshop .form .item .prettyradio {
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-right: 20px;
}

.webshop .form .item .prettycheckbox input, .webshop .form .item .prettyradio input {
    display: none;
}

.webshop .form .item .prettyradio a {
    width: 25px;
    height: 25px;
    background-position: -400px -16px;
    background-repeat: no-repeat;
    display: block;
    float: left;
    cursor: pointer;
    margin: 3px 5px 0px 0px;
}

.webshop .form .item .prettyradio a.disabled {
    cursor: not-allowed;
}

.webshop .form .item .prettyradio a.checked {
    background-position: -425px -16px;
}

.webshop .form .item .prettycheckbox a {
    width: 25px;
    height: 25px;
    background-position: -300px -16px;
    background-repeat: no-repeat;
    display: block;
    float: left;
    cursor: pointer;
    margin: 3px 5px 0px 0px;
}

#european_report {
   width:auto;
   max-width:100%;
}

.webshop .form .item .prettycheckbox a.disabled {
    cursor: not-allowed;
}

.webshop .form .item .prettycheckbox a.checked {
    background-position: -325px -16px;
}

.webshop .form .item .prettyradio a.disabled {
    opacity: 0.3;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    filter: alpha(opacity=30);
}

.webshop .form .item .prettycheckbox label, .webshop .form .item .prettyradio label {
    font-family: StoneSansITCPro-Medium;
    font-size: 14px;
    display: block;
    float: left;
    font-weight: normal;
    height: 30px;
    line-height: 20px;
    margin: 0px;
    text-align: left;
    cursor: pointer;
    width: auto;
    max-width: 85%;
    padding-top: 8px;
}

.webshop .form .item .prettycheckbox label.disabled, .webshop .form .item .prettyradio label.disabled {
    cursor: not-allowed;
}

.webshop .breakpoint {
    border-top: 1px solid #b2b2b2;
    margin-top: 20px;
    min-height: 50px;
}

.webshop .breakpoint .inner {
    display: block;
    margin-top: -10px;
}

.webshop .breakpoint span {
    color: #808080;
    background-color: #fff;
    font-size: 14px;
    font-family: sans-serif;
    text-align: center;
    padding: 4px 12px;
}

.webshop .right-container {
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: right;
}

.webshop .right-container a {
    float: none;
    padding-top: 10px;
    padding-bottom: 10px;
}

.webshop .center-container {
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: center;
}

.webshop .center-container a {
    float: none;
    padding-top: 10px;
    padding-bottom: 10px;
}

.webshop .action-errors {
    color: #be001e;
    font-size: 14px;
    padding-top: 0;
}

.webshop .center-container.description {
    color: #666;
    font-size: 14px;
}

.webshop .form .status {
    width: 64px;
}

.webshop .form .status i {
    display: block;
    float: left;
    background-position: -420px -41px;
}

.webshop .form .status i.ok {
    background-position: -350px -41px;
}

.webshop .form .status i.error {
    background-position: -385px -41px;
}

.webshop .form .errors {
    color: #666;
    font-style: 14px;
    padding-top: 7px;
}

.webshop .form .control-label {
    display: block;
    text-align: left;
    line-height: 22px;
    color: #666;
    font-size: 13px;
    font-weight: normal;
    padding-top: 7px;
}

.webshop .form .form-group .control {
    padding-right: 0;
}

.webshop .form .form-group .status {
    padding-right: 0;
}

.webshop .form .input-text {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    color: #282B34;
    font-family: StoneSansITCPro-Medium;
    font-size: 13px;
    outline: none;
    padding: 1px 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-top: 1px solid gray;
}

.webshop .form .input-text:disabled {
    color: #aaa;
}

.webshop .form .input-text.disabled {
    background-color: #e1e1e1;
    cursor: default;
    color: gray;
    cursor: not-allowed;
    z-index: -1;
    position: relative;
}

.webshop .package .box h2.price {
    font-size: 32px !important;
    line-height: 36px !important;
    color: #04b675;
}

.webshop .package .box h3.old-price {
    text-decoration: line-through;
    color: #005ca9;

}

.webshop .package .more.bottom {
    padding-top: 30px !important;
}

.webshop .package .more.bottom:active {
    text-decoration: none;
}

.webshop .webshop-review .item {
    display: block;
    float: left;
    width: 100%;
}

.webshop .webshop-review .input-text {
    color: #aaa;
    font-size: 13px;
    padding: 9px 10px;
    background: aliceblue;
}

.webshop .webshop-review .text-right {
    text-align: right;
}

.webshop .webshop-review hr {
    display: block;
    float: left;
    width: 100%;
    color: #ddd;
    margin-top: 25px;
}

.webshop .webshop-review #review-total {
    color: #005ca9;
    font-size: 30px;
    padding: 0;
    margin-top: 6px;
}

.webshop .webshop-review h3 {
    margin-top: 10px;
    font-size: 20px;
}

.webshop .pzo-payment-container {
    padding: 0;
    padding-bottom: 15px;
}

.webshop .pzo-payment-container .ajax-loader {
    margin-left: 2px;
    margin-top: -10px;
}

.webshop .home-payment-container, .webshop .form-payment-container,
.webshop .home-payment-container, .webshop .form-payment-container{
    padding-bottom: 15px;
}

.webshop .home-payment-container .ajax-loader, .webshop .form-payment-container .ajax-loader,
.webshop .home-payment-container .ajax-loader, .webshop .form-payment-container .ajax-loader{
    margin-left: 2px;
    margin-top: -10px;
}

.webshop #lead-box-wrapper {
    position: absolute;
    top: 30%;
    left: 70%;
}

.webshop #dzo-lead-box-wrapper,
.webshop #dlm-lead-box-wrapper{
    position: absolute;
    top: 25%;
    left: 75%;
}

.webshop .foreign-show {
    display: none;
}

.webshop .transparent-border {
    border: 1px solid transparent;
}

.webshop .home-see-allowances {
    font-family: StoneSansITCPro-Medium;
    font-weight: normal;
    font-style: normal;
    color: #999;
    cursor: pointer;
}

.webshop .home-see-allowances i {
    margin: 0 10px 0 10px;
}

.webshop #invoice_submit_button, .webshop #invoice_back_button {
    cursor: pointer;
}

.webshop .form .select2-container {
    width: 100% !important;
}

.webshop .form .select2-selection {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #282B34;
    font-family: StoneSansITCPro-Medium;
    font-size: 13px;
    outline: none;
    padding: 2px;
    height: 35px;
    line-height: 35px;
    border-top: 1px solid gray;
}

.webshop .form .select2-container--disabled .select2-selection__rendered {
    color: #b2b2b2 !important;
}

.webshop .form .select2-selection__arrow {
    display: block;
    width: 35px;
    height: 29px;
    background: url('/../../assets/images/frontend/sprite.png') no-repeat -910px -44px;
    border-left: 1px solid #e1e1e1;
    margin-top: 2px;
}

.webshop .form .select2-selection__arrow b {
    display: none;
}

.city-selector {
    width: auto;
    text-align: center;
    color: green;
}

.select2-highlighted .city-selector {
    color: #000;
}

.tooltipster-default {
    background-color: white;
    border: 1px solid #e1e1e1;
    max-width: 280px;
    border-radius: 0;
}

.tooltipster-default .tooltipster-content {
    font-family: StoneSansITCPro-Medium;
    color: #282b34;
    line-height: 21px;
    padding: 12px 16px;
}

.select2-dropdown {
    border: 1px solid #e1e1e1 !important;
    border-top: none !important;
}

.select2-selection__rendered {
    color: #282828 !important;
}

.select2-results__option:first-child {
    border-top: none;
}

.select2-results__option {
    padding: 8px 6px;
    border-top: 1px solid #e1e1e1;
    font-family: StoneSansITCPro-Medium;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #e1e1e1;
    color: #000;
}

.select2-search--dropdown .select2-search__field {
    font-family: StoneSansITCPro-Medium;
}

.datepicker-dropdown {
    font-family: StoneSansITCPro-Medium;
    font-weight: normal;
    border-bottom: 1px solid #bbb;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-top: 3px;
    padding: 0;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
}

.datepicker-dropdown:before, .datepicker-dropdown:after {
    display: none;
}

.datepicker table {
    background-image: url('/../../assets/images/frontend/datepicker-background.jpg');
    background-repeat: repeat-x;
}

.datepicker table thead tr {
    background-color: cornflowerblue;
}

.datepicker table tr td {
    font-family: StoneSansITCPro-Medium;
    font-size: 11px;
    background-color: #fff;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    height: auto;
    padding: 5px;
    padding-top: 8px;
    line-height: 13px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.datepicker table tr td.day:hover, .datepicker table tr td.active {
}

.datepicker table tr td.disabled {
    background-color: #fff;
    color: #cbcbcb;
}

.datepicker table tr td.day.new {
    display: none;
}

.datepicker table tr td.today {
    color: #fff;
    background-color: gray;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.datepicker table tr td.today:hover {
    color: #000;
    background-color: #eee;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.datepicker table tr th {
    font-family: StoneSansITCPro-Medium;
    font-size: 12px;
    color: #eee;
    background-color: slateblue;
    padding: 0;
    padding-top: 4px;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
}

.datepicker table tr th.datepicker-switch, .datepicker table tr th.next, .datepicker table tr th.prev {
    color: #fff;
    background-color: cornflowerblue;
    height: 32px;
}

.datepicker table tr th.next, .datepicker table tr th.prev {
    font-size: 20px;
    font-weight: normal;
}

.datepicker table tr th.datepicker-switch:hover, .datepicker table tr th.next:hover, .datepicker table tr th.prev:hover {
    background-color: cornflowerblue;
}

.datepicker table tr td span {
    display: block;
    width: 25%;
    height: 32px;
    line-height: 34px;
    float: left;
    cursor: pointer;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    margin: 0;
}

.full-width {
    width: 100%;
}

.force-text-center {
    text-align: center !important;
}

.text-bold {
    font-weight: bold;
}

/* pzo shop main containers */
.pzo-animated .text-left {
    text-align: left !important;
}

#pzo-animated-container {
    display: block;
    float: left;
    width: 100%;
    position: absolute;
    overflow: hidden;
}

#pzo-form-container {
    display: none;
}

#pzo-form-insured-2 {
    display: none;
}

#pzo-form-persons-container {
    display: none;
}

#pzo-form-review-container {
    display: none;
}

#pzo-form-prev {
    padding-top: 5px;
}

.pzo-form-review-prev {
    position: absolute;
    margin-top: -11px;
    z-index: 1;
}

#pzo-animated-unexpected-errors {
    display: none;
    color: #be001e;
    padding-bottom: 30px;
}

#pzo-payment, #home-payment, #dzo-payment, #dlm-payment, #form-payment {
    display: inline-block;
    width: 130px;
    padding: 0;
    margin-top: -28px;
}

#pzo-payment.disabled {
    opacity: 0.5;
}

#pzo-form-persons-container .pzo-form-next-loader {
    right: 0;
    margin-right: -54px !important;
    margin-top: -32px !important;
}

/* webshop pzo animated styles */
.pzo-animated {
    background: #d9ecfb !important;
}

.pzo-animated .row {
    width: 100%;
    margin: 0;
}

.pzo-animated .inner-section .content {
    float: left;
    padding-top: 20px;
    position: relative;
    z-index: 2;
    padding-right: 10%;
}

.pzo-animated .inner-section .content.step-progress {
    padding-right: 0;
}

.pzo-animated .step-progress ul {
    position: relative;
    z-index: 99;
    padding-top: 25px;
}

.pzo-animated .step-progress ul li {
    padding-left: 10px;
    display: table-cell;
    vertical-align: middle;
}

.pzo-animated .step-progress ul li a {
    color: #282b34;
    padding: 3px 11px;
    background-color: #f4f4f4;
    display: inline-block;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border: 2px solid white;
    -webkit-transition: all 200ms ease-out 0ms;
    -moz-transition: all 200ms ease-out 0ms;
    -o-transition: all 200ms ease-out 0ms;
    transition: all 200ms ease-out 0ms;
}

.pzo-animated .step-progress ul li a:hover {
    background-color: #fff;
    text-decoration: none;
}

.pzo-animated .step-progress ul li a.active {
    background-color: #ec6502;
    color: white;
}

.pzo-animated .form-box {
    display: none;
    width: 400px;
    position: absolute;
    top: 23%;
    right: 28%;
    z-index: 20;
    background-color: white;
    border: 1px solid #e1e1e1;
}

.pzo-animated .form-box.map {
    width: 500px;
    top: 11%;
    right: 20%;
}

.pzo-animated .form-box .map-container {
    text-align: center;
    position: relative;
    left: 50%;
    margin-left: -196px;
    width: 392px;
    height: 266px;
}

.pzo-animated .form-box .map-container img {
    position: absolute;
    z-index: 1;
}

.pzo-animated .form-box .map-container img.continent {
    display: none;
}

.pzo-animated .form-box .map-container a {
    position: absolute;
    z-index: 2;
}

.pzo-animated .form-box .map-container img#world-map {
    position: relative;
}

.pzo-animated .form-box .map-container img#continent-europe {
    top: 0;
    left: 160px;
}

.pzo-animated .form-box .map-container a#select-europe {
    width: 85px;
    height: 85px;
    top: 40px;
    left: 162px;
}

.pzo-animated .form-box .map-container img#continent-asia {
    top: 0;
    right: 0;
}

.pzo-animated .form-box .map-container a#select-asia {
    width: 125px;
    height: 134px;
    top: 40px;
    left: 246px;
}

.pzo-animated .form-box .map-container img#continent-northamerica {
    top: 0;
    left: 0;
}

.pzo-animated .form-box .map-container a#select-northamerica {
    width: 148px;
    height: 117px;
    top: 32px;
    left: 15px;
}

.pzo-animated .form-box .map-container img#continent-southamerica {
    left: 0;
    bottom: 0;
}

.pzo-animated .form-box .map-container a#select-southamerica {
    width: 96px;
    height: 82px;
    bottom: 36px;
    left: 67px;
}

.pzo-animated .form-box .map-container img#continent-africa {
    left: 150px;
    bottom: 0;
}

.pzo-animated .form-box .map-container a#select-africa {
    width: 85px;
    height: 85px;
    bottom: 57px;
    left: 162px;
}

.pzo-animated .form-box .map-container img#continent-australia {
    right: 0;
    bottom: 0;
}

.pzo-animated .form-box .map-container a#select-australia {
    width: 94px;
    height: 56px;
    bottom: 37px;
    right: 9px;
}

.pzo-animated .form-box h3#map-continent-title {
    color: #fff;
    font-size: 16px;
    line-height: 33px;
    margin-top: -38px;
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    z-index: 2;
}

.pzo-animated .form-box .country-container {
    display: none;
}

.pzo-animated .form-box #continent-europe-container {
    display: block;
}

.pzo-animated .pzo-sport-ski-radio-container {
    padding-left: 0;
    padding-top: 5px;
}

.pzo-animated .form-box .step-number {
    font-family: StoneSansITCPro-Medium;
    color: white;
    display: block;
    width: 36px;
    margin: auto;
    text-align: center;
    height: 36px;
    background-color: #ec6502;
    border: 3px solid white;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    left: 50%;
    top: -16px;
    position: absolute;
    line-height: 34px;
    margin-left: -18px;
}

.pzo-animated .form-box > .form-content {
    padding: 1.5%;
    padding-bottom: 4%;
}

.pzo-animated .form-box > .form-content h2 {
    color: #282b34;
    font-size: 21px !important;
    line-height: 36px !important;
    font-weight: normal !important;
    text-align: center;
    padding: 6px 0 4px 0 !important;
}

.pzo-animated .form-box > .form-content .item .control-label {
    font-family: StoneSansITCPro-Medium;
    font-size: 14px;
    color: #282b34;
    line-height: 16px;
    text-align: left;
}

.pzo-animated .form-box > .form-content .item .status {
    width: 16.66666667%;
}

.pzo-animated .form-box #insurance-death-container {
    display: none;
    padding-top: 10px;
}

.pzo-animated .form-box #insurance-death-container.pzo-group {
    padding-top: 0 !important;
}

.pzo-animated .form-box > .form-content .item .control .fa-calendar {
    color: #b2b2b2;
    right: 0;
    display: block;
    position: absolute;
    margin-top: -25px;
    margin-right: 10px;
    font-size: 18px;
}

.pzo-animated .form-box > .form-content .item .input-text {
    color: #282b34;
    border: 1px solid #e1e1e1;
    border-top: 1px solid gray;
}

.pzo-animated .form-box > .form-content .item .select2-selection {
    color: #282b34;
    border: 1px solid #e1e1e1;
    border-top: 1px solid gray;
}

.pzo-animated .form-box > .form-content .item .input-text[disabled] {
    background-color: #e1e1e1;
    cursor: default;
    color: gray;
}

.pzo-animated .form-box > .form-content .item .prettycheckbox, .pzo-animated .form-box > .form-content .item .prettyradio {
    margin-right: 8px;
}

.pzo-animated .form-box > .form-content .item .prettycheckbox.row, .pzo-animated .form-box > .form-content .item .prettyradio.row {
    width: 100%;
}

.pzo-animated .form-box > .form-content .item .prettycheckbox.row a, .pzo-animated .form-box > .form-content .item .prettyradio.row a {
    margin-right: 10px;
}

.pzo-animated .form-box > .form-content .item .prettyradio a {
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    float: left;
    cursor: pointer;
    margin: 3px 5px 0px 0px;
    background: #fefefe;
    margin-right: 10px;
    background-color: #ececec;
    border: 1px solid #e1e1e1;
    border-top: 1px solid #c6c7c7;
    width: 18px;
    height: 18px;
}

.pzo-animated .form-box > .form-content .item .prettyradio a.disabled {
    opacity: 0.3;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    filter: alpha(opacity=30);
}

.pzo-animated .form-box > .form-content .item .prettyradio a.checked:before {
    position: absolute;
    line-height: 14px;
    font-size: 28px;
    color: #4e4e4e;
    content: "●";
    height: inherit;
    display: block;
    padding: .3px 0 0 0.4px;
    margin-left: -1px;
}

.pzo-animated .form-box > .form-content .item .prettyradio label {
    padding-top: 5px;
    padding-bottom: 4px;
    line-height: 18px;
    max-width: 81%;
    height: auto;
}

.pzo-animated .form-box > .form-content .item .inline-control-label {
    width: 80%;
}

.pzo-animated .form-box > .form-footer {
    float: left;
    width: 100%;
    background-color: #f4f4f4;
    padding: 1.5%;
}

.pzo-animated .form-box > .form-footer > a {
    font-size: 14px;
    padding-bottom: 6px;
    background-color: gray;
}

.pzo-animated .form-box > .form-footer > a.disabled {
    cursor: not-allowed;
    background-color: #e1e1e1 !important;
}

.pzo-animated .bkg-top {
    position: absolute;
    height: 87%;
    top: 0;
}

.pzo-animated .bkg-top .sun .pzo-ground-items {
    display: display;
}

.pzo-animated .bkg-top .sun {
    position: absolute;
    top: 0;
    right: 20%;
    width: 6.8%;
    z-index: 1;
}

.pzo-animated .bkg-top .grass {
    position: absolute;
    bottom: -3%;
    z-index: 1;
}

.pzo-animated .bkg-top .plane-middle {
    position: absolute;
    bottom: -3%;
    right: 19%;
    width: 18%;
    z-index: 1;
}

.pzo-animated .bkg-top .wind-indicator {
    position: absolute;
    bottom: -10%;
    right: 15%;
    width: 6.4%;
}

.pzo-animated .bkg-top .tower {
    position: absolute;
    bottom: -3%;
    right: 1%;
    width: 8.1%;
    z-index: 2;
}

.pzo-animated .bkg-tarmac {
    position: absolute;
    height: 13%;
    bottom: 0;
    background: url('/../../assets/images/pzo/tarmac.png');
    z-index: 10;
}

.pzo-animated .bkg-top .pzo-clouds {
    display: none;
    position: absolute;
    z-index: 1;
}

.pzo-animated .bkg-top #cloud-1 {
    top: 24%;
    left: 3%;
    width: 44%;
}

.pzo-animated .bkg-top #cloud-2 {
    top: 10%;
    right: 5%;
    width: 35%;
}

.pzo-animated .bkg-top #cloud-3 {
    top: 75%;
    left: 18%;
    width: 24%;
}

.pzo-animated .bkg-top #cloud-4 {
    top: 63%;
    right: 12%;
    width: 31%;
}

.pzo-animated .bkg-top #flying-plane {
    display: block;
    position: absolute;
    right: 110%;
    bottom: 15%;
    width: 40%;
    z-index: 3;
}

.pzo-animated .plane-animate {
    position: absolute;
    width: 100%;
    left: -60%;
    bottom: 0;
    z-index: 2;
}

.pzo-animated .plane-animate img {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 3;
}

.pzo-animated .plane-animate .plane-passengers {
    position: absolute;
    right: 19%;
    bottom: 36%;
    z-index: 3;
    width: 24%;
}

.pzo-animated .plane-animate .plane-passengers img {
    display: none;
    bottom: 0;
    margin-bottom: 58.5%;
}

.pzo-animated .plane-animate .plane-passengers img#pzo-plane-dad {
    right: 2%;
    width: 6.4%;
}

.pzo-animated .plane-animate .plane-passengers img#pzo-plane-mom {
    right: 15.5%;
    width: 6%;
}

.pzo-animated .plane-animate .plane-passengers img#pzo-plane-child-1 {
    right: 29%;
    width: 5.5%;
}

.pzo-animated .plane-animate .plane-passengers img#pzo-plane-child-2 {
    right: 41%;
    width: 5.5%;
}

.pzo-animated .plane-animate .plane-passengers img#pzo-plane-child-3 {
    right: 54%;
    width: 6%;
}

.pzo-animated .plane-animate .plane-passengers img#pzo-plane-child-4 {
    right: 67.8%;
    width: 5.5%;
}

.pzo-animated .bus-animate {
    position: absolute;
    right: -30%;
    bottom: 5%;
    width: 30%;
    z-index: 3;
}

.pzo-animated .bus-animate img#pzo-bus {
    width: 100%;
}

.pzo-animated .bus-animate img#pzo-bus-counterclock {
    width: 100%;
    display: none;
}

.pzo-animated .bus-animate img#pzo-bus-dad {
    position: absolute;
    right: 61%;
    bottom: 57%;
    width: 8.5%;
    z-index: 3;
}

.pzo-animated .bus-animate img#pzo-bus-mom {
    position: absolute;
    right: 44%;
    bottom: 57%;
    width: 7.3%;
    z-index: 3;
}

.pzo-animated .bus-animate img#pzo-bus-child-1 {
    position: absolute;
    right: 31.5%;
    bottom: 57%;
    width: 7%;
    z-index: 3;
}

.pzo-animated .bus-animate img#pzo-bus-child-2 {
    position: absolute;
    right: 25.5%;
    bottom: 57%;
    width: 7%;
    z-index: 3;
}

.pzo-animated .bus-animate img#pzo-bus-child-3 {
    position: absolute;
    right: 19%;
    bottom: 57%;
    width: 7%;
    z-index: 3;
}

.pzo-animated .bus-animate img#pzo-bus-child-4 {
    position: absolute;
    right: 14%;
    bottom: 57%;
    width: 7%;
    z-index: 3;
}

.pzo-animated .bus-animate img#pzo-bus-dad.counterclock {
    right: 32%;
    width: 8.2%;
}

.pzo-animated .bus-animate img#pzo-bus-mom.counterclock {
    right: 49%;
    width: 7.3%;
}

.pzo-animated .bus-animate img#pzo-bus-child-1.counterclock {
    right: 61%;
    width: 7%;
}

.pzo-animated .bus-animate img#pzo-bus-child-2.counterclock {
    right: 67%;
    width: 7%;
}

.pzo-animated .bus-animate img#pzo-bus-child-3.counterclock {
    right: 73%;
    width: 7%;
}

.pzo-animated .bus-animate img#pzo-bus-child-4.counterclock {
    right: 79%;
    width: 7%;
}

.pzo-animated .bus-animate .passenger-standing {
    display: none;
    position: absolute;
    bottom: 80%;
    z-index: -1;
}

.pzo-animated .bus-animate img#dad-standing {
    width: 8.5%;
    right: 50%;
}

.pzo-animated .bus-animate img#mom-standing {
    width: 8.5%;
    right: 50%;
}

.pzo-animated .bus-animate img#child-1-standing {
    width: 7%;
    right: 49%;
}

.pzo-animated .bus-animate img#child-2-standing {
    width: 7%;
    right: 49%;
}

.pzo-animated .bus-animate img#child-3-standing {
    width: 7%;
    right: 49%;
}

.pzo-animated .bus-animate img#child-4-standing {
    width: 7%;
    right: 49%;
}

.pzo-animated .continent-background {
    display: none;
    position: absolute;
    right: 0;
    bottom: -1%;
}

.pzo-animated #pzo-plane-closed {
    display: block;
}

.pzo-animated #pzo-plane-opened {
    display: none;
}

#pzo-checklist #checklist-total {
    margin: 0;
    font-size: 24px;
    padding-top: 9px;
}

#pzo-checklist .disclaimer {
    color: #be001e;
    line-height: 17px;
}

#pzo-checklist .input-text {
    height: auto;
    padding-bottom: 5px;
}

#home-payment, #dzo-payment {
    margin-bottom: -10px;
    margin-left: -90px;
}

.home-package-font-size {
    font-size: 13px !important;
}

/* damage reports */
.damage-reports.errors {
    display: block;
    color: #be001e !important;
    font-size: 14px !important;
    padding-top: 0 !important;
}

.damage-reports .form fieldset {
    padding-bottom: 0;
}

.damage-reports .input-group[class*=col-] {
    float: left;
    padding-left: 15px;
    padding-right: 15px;
}

.damage-reports .spacer-20 {
    height: 20px;
}

.damage-reports .form .control-label {
    line-height: 18px;
}

.damage-reports .form .input-text {
    padding: 3px 10px 0 10px;
}

.damage-reports .form textarea {
    resize: vertical;
    padding: 10px !important;
}

.damage-reports .item .form-group label.prefix-text {
}

.damage-reports .form .item .prettycheckbox label, .damage-reports .form .item .prettyradio label {
    font-family: sans-serif;
    font-size: 13px;
    display: block;
    float: left;
    font-weight: normal;
    height: auto;
    line-height: 16px;
    margin: 0px;
    text-align: left;
    cursor: pointer;
    width: auto;
    max-width: 80%;
    padding-top: 8px;
}

.damage-reports .form .item .prettyradio.row, .damage-reports .form .item .prettyradio.row {
    width: 100%;
}

.payment-user-of-compensation {
    display: none;
}

#official_name_container {
    display: none;
}

.unlimited-duration-margin {
    margin-left: -50px !important;
}

.unlimited-duration-span {
    font-size: 16px;
    color: #005ca9;
    font-weight: 900;
}

/* footer styles */
#footer {
    float: left;
    width: 100%;
    background: #fff url('/../../assets/images/frontend/bg-footer.jpg') repeat-x top;
}

.footer .upper {
    margin: 0 auto;
}

.footer .upper .wrapper {
    display: block;
    float: left;
    width: 100%;
    padding: 50px 0 15px 0;
}

.footer .upper h4 {
    font-family: StoneSansITCPro-SemiBold, sans-serif;
    font-size: 15px;
    line-height: 21px;
}

.footer .upper .items {
    padding-bottom: 25px;
}

.footer .upper .items a {
    display: block;
    color: #999;
    font-family: Arial, sans-serif;
    font-size: 13px;
    line-height: 17px;
}

/* footer bottom */
.footer .bottom {
    display: block;
    float: left;
    width: 100%;
    background-color: #e1e1e1;
}

.footer .bottom .trademarks {
    text-align: center;
    font-family: sans-serif;
    font-size: 12px;
    line-height: 25px;
    padding: 8px 0;
}

.footer .bottom .trademarks a {
    color: #282b34;
}

.footer .bottom .social-links {
    padding: 8px 0;
}

.footer .bottom .social-links a {
    float: left;
    margin-right: 5px;
    width: 25px;
    height: 25px;
}

.footer .bottom .social-links a.facebook {
    background-position: 0 -16px;
}

.footer .bottom .social-links a.facebook:hover {
    background-position: -100px -16px;
}

.footer .bottom .social-links a.youtube {
    background-position: -75px -16px;
}

.footer .bottom .social-links a.youtube:hover {
    background-position: -175px -16px;
}

@media (max-width: 1400px) {

    /* webshop stypes */
    .pzo-animated .form-box {
        right: 19%;
    }

    /* damage reports */
    .damage-reports .form .item .prettycheckbox label, .damage-reports .form .item .prettyradio label {
        width: 72%;
        height: auto;
    }
}

/* responsive stypes */
@media (max-width: 1200px) {

    /* homepage banner */
    #main-slider .slide .info-box {
        width: 40%;
    }

    .flash-box {
        width: 60%;
    }
}

/* responsive stypes */
@media (max-width: 992px) {

    /* customized cols */
    .col-md-45 {
        width: 50%;
    }

    .col-md-offset-45 {
        margin-left: 0;
    }

    /* main nav */
    ul.primary.main-left {
        padding-right: 10%;
    }

    /* header styles */
    .search-container {
        padding-bottom: 10px;
        float: right;
        padding-right: 9%;
        padding-left: 20%;
        width: 100%;
        margin-top: 0;
    }

    /* homepage banner */
    .main-slider-container {
        height: 350px;
    }

    #main-slider .slide {
        min-height: 350px;
    }

    #main-slider .slide .info-box {
        width: 50%;
        margin-left: 80px;
    }

    /* layout styles */
    .breadcrumb-container {
        padding-left: 2%;
        width: 98%;
    }

    .inner-section {
        width: 100%;
    }

    /* homepage styles */
    #homepage .box-container {
        position: relative;
        display: block;
        float: left;
    }

    #homepage .news-section-container {
        margin-top: 265px;
    }

    #homepage .grid-products {
        padding-bottom: 60px;
    }

    #homepage .grid-products .item {
        padding-bottom: 15px;
    }

    #homepage .grid-products .inner-section .group {
        padding-top: 0;
        padding-bottom: 0;
    }

    /* menu-pages styles */
    #page .content {
        width: 100%;
    }

    #page .inner-section {
        display: block;
        float: left;
        width: 100%;
        padding-left: 2%;
        padding-right: 2%;
    }

    #page .widgets .breakpoint {
        display: block;
        float: left;
        width: 100%;
    }

    #page .widgets .numeric-lists {
        display: block;
        float: left;
        width: 100%;
    }

    /* flash-box styles*/
    .flash-box {
        width: 80%;
    }

    /* webshop */
    .webshop .breakpoint {
        display: block;
        float: left;
        width: 100%;
    }

    .webshop .form fieldset {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .webshop .form .status {
        padding-left: 5% !important;
        width: 16.66666667% !important;
    }

    .webshop .form .item {
        padding: 0 5%;
    }

    .webshop .form .item .form-group {
        margin-bottom: 3px;
    }

    .webshop .center-container {
        display: block;
        float: left;
    }

    .webshop .form .errors {
        color: crimson;
        font-size: 12px;
        padding-top: 7px;
    }

    .webshop .form .item .prettyradio label {
        width: 75%;
        line-height: 17px;
        padding-top: 10px !important;
    }

    .webshop .prettyradio label, .webshop .form .item .prettyradio {
        padding-top: 0 !important;
        width: 100%;
    }

    .webshop .prettycheckbox label, .webshop .form .item .prettycheckbox {
        width: 100%;
    }

    .webshop .center-container {
        padding-bottom: 20px;
    }

    .webshop .center-container a {
        float: left;
    }

    .webshop .center-container.action-errors {
        width: 100%;
        text-align: left;
    }

    .webshop .ajax-loader {
        display: block;
        float: left;
        margin-left: 145px !important;
        margin-top: 0;
    }

    .webshop .pzo-payment-container {
        float: left;
        width: 100%;
        padding-top: 66px;
    }

    .webshop .pzo-payment-container #pzo-payment {
        position: relative;
        left: 50%;
        margin-left: -65px;
    }

    .webshop #pzo-review-errors {
        text-align: center !important;
    }

    .webshop #pzo-terms-conditions {
        padding-left: 0;
    }

    .webshop .webshop-review .item {
        padding-left: 0;
        padding-right: 0;
    }

    .webshop .webshop-review .form-group .control {
        padding-right: 15px;
    }

    .webshop .webshop-review .form-group .col-xs-3 {
        padding-right: 0 !important;
    }

    .webshop .webshop-review .form-group .col-xs-4 {
        padding-left: 3px;
    }

    .webshop .webshop-review .input-text {
        height: auto;
        padding-bottom: 5px;
    }

    .webshop .webshop-review .text-right {
        text-align: left;
    }

    .webshop .webshop-review #review-total {
        padding-top: 26px !important;
        text-align: right;
        font-size: 25px;
    }

    .webshop #lead-box-wrapper, .webshop #dzo-lead-box-wrapper {
        position: relative !important;
        left: 50%;
        margin-left: -25%;
    }

    #pzo-form-review-container h3 {
        margin-top: 26px;
        font-size: 18px;
        float: right;
    }

    #pzo-form-review-container .ajax-loader {
        margin-left: 174px !important;
        margin-top: -17px !important;
        position: relative;
    }

    #checklist-insurance-total {
        text-align: right;
    }

    #checklist-total {
        text-align: right;
        padding-top: 21px !important;
    }

    /* pzo styles */
    #page .inner-section#pzo-form-container {
        display: none;
    }

    .pzo-animated .no-lpadd {
        padding-left: 15px !important;
    }

    .pzo-animated .content {
        width: 85% !important;
        font-size: 13px !important;
        line-height: 19px !important;
        padding-top: 0 !important;
    }

    .pzo-animated .inner-section .content {
        padding-right: 0;
    }

    .pzo-animated .content.step-progress {
        width: 15% !important;
    }

    .pzo-animated .step-progress ul li {
        display: block;
        margin-bottom: 7px;
    }

    .pzo-animated .step-progress ul li a {
        padding: 2px 9px !important;
    }

    .pzo-animated .content h1 {
        font-size: 22px !important;
        line-height: 30px !important;
    }

    .pzo-animated .content h1 span {
        background-position: -630px -46px !important;
    }

    .pzo-animated .form-box#form-box-1 {
        top: 38% !important;
    }

    .pzo-animated .form-box > .form-content .item .control-label {
        text-align: left;
    }

    .pzo-animated .form-box > .form-content h2 {
        padding: 3px 0 0px 0 !important;
    }

    .pzo-animated .form-box > .form-content {
        padding-bottom: 1% !important;
    }

    .pzo-animated .prettyradio label, .pzo-animated .form .item .prettyradio {
        width: 100%;
        padding-top: 5px !important;
    }

    .pzo-animated .prettycheckbox label, .pzo-animated .form .item .prettyradio label {
        width: 65%;
        line-height: 17px;
        padding-top: 6px !important;
    }

    .pzo-animated .form-box > .form-content .item .prettyradio label {
        max-width: 65%;
    }

    .pzo-animated .form-box > .form-content .item .inline-control-label {
        width: 75%;
    }

    .pzo-animated .form .form-group .status {
        padding-left: 7px !important;
    }

    #pzo-form-persons-container fieldset .item {
        padding-left: 0;
    }

    .pzo-form-review-prev {
        position: absolute;
        left: 50%;
        margin-top: -22px;
        margin-left: -42px;
    }

    .pzo-animated {
        overflow-x: hidden;
    }
}

/* responsive stypes */
@media (max-width: 768px) {
    .col-md-45 {
        width: 100%;
    }

    .col-md-offset-45 {
        margin-left: 0;
    }

    /* layout styles */
    .logo-container #logo {
        width: 90px;
        height: 84px;
        margin-left: -45px;
        margin-top: -16px;
    }

    .top-links ul {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }

    .top-links ul li:last-child {
        float: right;
    }

    .top-links ul li a {
        color: #999;
        background-color: #fff;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 3px;
    }

    .top-links ul li a:hover {
        color: #7fadd4;
        background-color: #fff;
    }

    .top-links ul li a.selected {
        color: #7fadd4;
        background-color: transparent;
    }

    .breadcrumb-container {
        padding-left: 2%;
        width: 98%;
    }

    .inner-section {
        padding-left: 3%;
        padding-right: 3%;
    }

    /* homepage banner */
    #main-slider .slide {
        background-size: 100% auto !important;
    }

    #main-slider .slide .info-box {
        width: 100%;
        bottom: 0;
        position: relative;
        margin: 90px 0 0 120px;
        opacity: 1;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 30px;
        margin-left: 0;
    }

    #main-slider .owl-buttons {
        display: none;
    }

    /* homepage styles */
    #homepage .inner-section {
        padding-left: 1%;
        padding-right: 1%;
    }

    #homepage .news-section-container {
        margin-top: 510px;
    }

    #homepage .news-box {
        display: block;
        float: left;
        width: 100%;
    }

    #homepage .questions-box {
        display: block;
        float: left;
        width: 100%;
        margin-top: 30px;
    }

    #homepage .grid-products {
        padding-bottom: 50px;
    }

    #homepage .grid-products h2 {
        padding: 0 3%;
    }

    #homepage .grid-products .item {
        padding-right: 0;
    }

    #homepage .grid-products .inner-section {
        padding-left: 5%;
        padding-right: 5%
    }

    #homepage .social p {
        min-height: auto;
        padding-bottom: 0;
    }

    #homepage .social .col-xs-12 {
        padding-bottom: 20px;
    }

    /* pages styles */
    #page .breadcrumb-container {
        padding-left: 3%;
    }

    #page .inner-section {
        padding: 0;
    }

    #page .widgets > .icons {
        display: block;
        float: left;
    }

    /* box styles */
    #page .box-primary .box {
        min-height: auto;
    }

    #page .box-contact .box {
        min-height: auto;
    }

    /* box styles */
    .lead-container {
        margin-bottom: 40px;
        display: block;
        float: left;
        width: 100%;
    }

    /* search styles */
    .search-page input {
        display: block;
        width: 90%;
        margin: 10px auto;
        outline: none;
        padding: 0px 10px;
    }

    .search-page .search-form-submit {
        display: block;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 10px;
        right: 0px;
        width: auto;
    }

    /* news styles */
    .news .breadcrumb-container {
        padding-left: 3%;
    }

    .news .inner-section {
        padding: 0;
    }

    .news .no-padd {
        padding-left: 4% !important;
        padding-right: 4% !important;
    }

    .news .content {
        padding-bottom: 24px;
    }

    .news .filters.filter {
        margin-bottom: 35px;
    }

    .news .news-box {
        width: 100%;
        padding: 0;
        padding-bottom: 10px;
    }

    .news .news-content {
        display: block;
        float: left;
    }

    .news .news-content > h1 {
        padding-top: 8px;
    }

    /* webshop */
    .webshop .form fieldset {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .webshop .form .status {
        padding-left: 5% !important;
        width: 16.66666667% !important;
    }

    .webshop .form .item {
        padding: 0 5%;
    }

    .webshop .form .item .form-group {
        margin-bottom: 3px;
    }

    .webshop .center-container {
        display: block;
        float: left;
    }

    .webshop .form .errors {
        color: crimson;
        font-size: 12px;
        padding-top: 7px;
    }

    .webshop .form .item .prettyradio label {
        width: 75%;
        line-height: 17px;
        padding-top: 10px !important;
    }

    .webshop .prettyradio label, .webshop .form .item .prettyradio {
        padding-top: 0 !important;
        width: 100%;
    }

    .webshop .prettycheckbox label, .webshop .form .item .prettycheckbox {
        width: 100%;
    }

    .webshop .center-container {
        padding-bottom: 20px;
    }

    .webshop .center-container a {
        float: left;
    }

    .webshop .center-container.action-errors {
        width: 100%;
        text-align: left;
    }

    .webshop .ajax-loader {
        display: block;
        float: left;
        margin-left: 145px;
        margin-top: 0;
    }

    .webshop .webshop-review .item {
        padding-left: 0;
        padding-right: 0;
    }

    .webshop .webshop-review .form-group .control {
        padding-right: 15px;
    }

    .webshop .webshop-review .form-group .col-xs-3 {
        padding-right: 0 !important;
    }

    .webshop .webshop-review .form-group .col-xs-4 {
        padding-left: 3px;
    }

    .webshop .webshop-review .input-text {
        height: auto;
        padding-bottom: 5px;
    }

    .webshop .webshop-review .text-right {
        text-align: left;
    }

    .webshop #lead-box-wrapper, .webshop #dzo-lead-box-wrapper {
        position: relative !important;
        left: 0;
        margin-left: 0;
    }

    #pzo-terms-conditions {
        padding-left: 0;
    }

    /* pzo styles */
    #page .inner-section#pzo-form-container {
        display: none;
    }

    .pzo-animated .no-lpadd {
        padding-left: 15px !important;
    }

    .pzo-animated .content {
        width: 85% !important;
        font-size: 13px !important;
        line-height: 19px !important;
        padding-top: 0 !important;
    }

    .pzo-animated .inner-section .content {
        padding-right: 0;
    }

    .pzo-animated .content.step-progress {
        width: 15% !important;
    }

    .pzo-animated .step-progress ul li {
        display: block;
        margin-bottom: 7px;
    }

    .pzo-animated .step-progress ul li a {
        padding: 2px 9px !important;
    }

    .pzo-animated .content h1 {
        font-size: 22px !important;
        line-height: 30px !important;
    }

    .pzo-animated .content h1 span {
        background-position: -630px -46px !important;
    }

    .pzo-animated .form-box {
        width: 90%;
        left: 5%;
    }

    .pzo-animated .form-box#form-box-1 {
        top: 38% !important;
    }

    .pzo-animated .form-box.map {
        width: 90%;
    }

    .pzo-animated .form-box > .form-content .item .control-label {
        text-align: left;
    }

    .pzo-animated .form-box > .form-content h2 {
        padding: 3px 0 0px 0 !important;
    }

    .pzo-animated .form-box > .form-content {
        padding-bottom: 1% !important;
    }

    .pzo-animated .prettyradio label, .pzo-animated .form .item .prettyradio {
        width: 100%;
        padding-top: 5px !important;
    }

    .pzo-animated .prettycheckbox label, .pzo-animated .form .item .prettyradio label {
        width: 75%;
        line-height: 17px;
        padding-top: 6px !important;
    }

    .pzo-animated .form-box > .form-content .item .inline-control-label {
        width: 75%;
    }

    .pzo-animated .form .form-group .status {
        padding-left: 7px !important;
    }

    /* damage reports */
    .damage-reports .item .form-group label .prefix-text {
        float: left !important;
        margin-right: 5px;
    }

    /* footer styles */
    .footer .bottom .trademarks {
        text-align: left !important;
        padding-left: 5%;
        line-height: 18px;
    }

    .footer .bottom .trademarks span {
        display: block;
        width: 100%;
    }

    .footer .bottom .social-links {
        float: right;
    }

    .flash-box {
        width: 90%;
    }

    .text-left-small-device {
        text-align: left !important;
    }

    #home-payment {
        margin: 0 !important;
        margin-top: -10px !important;
        float: right;
    }

    #home-form-errors {
        text-align: center !important;
    }

    .unlimited-duration-margin {
        margin-left: 0 !important;
    }
}

/* Social share buttons */
.sharebuttons {
    margin-left: 10px;
}

.sharebuttons a {
    color: #fff !important;
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    font-size: 18px;
    padding: 8px 8px;
}

.sharebuttons a span {
    display: none;
}

.btn-whatsapp {
    background: #00E676;
}

.btn-gmail {
    background: #DD5043;
}

.btn-viber {
    background: #675DA9;
}

.btn-facebook {
    background: #3b5998;
}

.btn-twitter {
    background: #00acee;
}

.btn-google {
    background: #dd4b39;
}

.btn-pinterest {
    background: #c8232c;
}

.btn-linkedin {
    background: #0e76a8;
}

.btn-whatsapp:hover {
    background: #00E676;
    transform: scale(1.05);
    transition: transform .3s;
}

.btn-viber:hover {
    background: #675DA9;
    transform: scale(1.05);
    transition: transform .3s;
}

.btn-gmail:hover {
    background: #DD5043;
    transform: scale(1.05);
    transition: transform .3s;
}

.btn-facebook:hover {
    background: #3b5998;
    transform: scale(1.05);
    transition: transform .3s;
}

.btn-twitter:hover {
    background: #0089bd;
}

.btn-google:hover {
    background: #dd4b39;
    transform: scale(1.05);
    transition: transform .3s;
}

.btn-pinterest:hover {
    background: #9f1b23;
}

.btn-linkedin:hover {
    background: #0b5e85;
}

.viber-icon {
    background: url(/../../assets/images/frontend/viber_icon.png);
    width: 22px !important;
    height: 24px !important;
    background-size: 100%;
    background-repeat: no-repeat;
    display: block;
}

.display-inline {
    display: inline-block !important;
}

/* Giveaway */
.giveaway h1 {
    color: #005ca9;
    text-transform: none;
    font-weight: bold;
    padding-left: 0 !important;
}

.giveaway .negative-margin {
    margin-top: -70px;

}

.giveaway .description-small {
    border: 1px solid #979797;
}

.giveaway .product-box.description-small {
    background-color: #fff;
    border-top: 1px solid #e1e1e1;
    margin-top: 0;
    position: relative;
    text-align: center;
    float: left;
    width: 100%;
}

.giveaway .insurance-title-wrapper {
    height: 60px;
    width: 100%;
}

.giveaway .insurance-title-wrapper img {
    display: inline-block;
}

.giveaway .insurance-title-wrapper .insurance-title {
    display: inline-block;
    height: 100%;
}

.giveaway .insurance-title-wrapper .insurance-title span {
    color: #4a4a4a;
    margin-top: 10px;
    font-size: 20px;
    text-align: left;
}

.giveaway .insurance-button {
    height: 60px;
    margin-top: 15px;
}

.giveaway .insurance-button a {
    display: initial;
    padding: 10px 20px;
    color: #fff;
    font-family: StoneSansITCPro-Medium, sans-serif;
    font-size: 16px;
    text-shadow: 0 1px rgba(0, 0, 0, .1);
    -moz-text-shadow: 0 1px rgba(0, 0, 0, .1);
    -webkit-text-shadow: 0 1px rgba(0, 0, 0, .1);
}

.giveaway .giveaway-h2 {
    font-size: 2.286em !important;
    color: #282b34;
    height: auto;
    line-height: 1.2em;
    font-weight: normal !important;
}

.giveaway .breakpoint {
    border-top: 1px solid #b2b2b2;
    margin-top: 20px;
    min-height: 50px;
}

.giveaway .breakpoint .inner {
    display: block;
    margin-top: -10px;
}

.giveaway .breakpoint span {
    color: gray;
    background-color: #fff;
    font-size: 14px;
    font-family: sans-serif;
    text-align: center;
    padding: 4px 12px;
}

.giveaway label {
    float: left;
    font: 1.25em/140% sans-serif, Arial, Helvetica;
    margin-right: 1%;
    position: relative;
    top: 10px;
    width: 31%;
    color: #666;
}

.giveaway label.terms {
    float: none;
    font: 1.15em/140% sans-serif, Arial, Helvetica;
    margin-left: 10px;
    position: relative;
    top: 0;
    width: auto;
    color: #666;
}

.giveaway input[type="text"] {
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    border: none;
    -webkit-box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    -ms-box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    -o-box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    background: #DBDBDB;
    color: #000;
    font: 1.25em/120% sans-serif, Arial, Helvetica;
    height: 46px;
    margin-right: 0px;
    width: 100%;
    line-height: 240% \9;
    padding: 0px 1.6%;
    -webkit-transition: background 0.3s ease-in-out 0;
    -moz-transition: background 0.3s ease-in-out 0s;
    -o-transition: background 0.3s ease-in-out 0;
    transition: background 0.3s ease-in-out 0;
}

.giveaway input[type="text"]:focus {
    background-color: #FFFFFF;
    outline: none;
}

.giveaway .form-group {
    margin-bottom: 10px !important;
}

.giveaway .giveaway-button {
    background: #005eab;
    display: block;
    padding: 14px 0;
    border: 0px;
    width: 100%;
    overflow: hidden;
    font: 1.25em/120% sans-serif, Arial, Helvetica;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    border-radius: 3px;
    cursor: pointer;
    -webkit-transition: background 0.2s ease-out 0;
    -moz-transition: background 0.2s ease-out 0s;
    -o-transition: background 0.2s ease-out 0;
    transition: background 0.2s ease-out 0;
    text-decoration: none;
    text-transform: uppercase;
}

.giveaway .giveaway-button:hover {
    background: #b3d4fc;
    color: #FFFFFF;
    -webkit-transition: background 0.2s ease-out 0;
    -moz-transition: background 0.2s ease-out 0s;
    -o-transition: background 0.2s ease-out 0;
    transition: background 0.2s ease-out 0;
    text-decoration: none;
}

.giveaway .giveaway-button.send {
    margin-top: 10px;
}

.giveaway .giveaway-button.disabled {
    background: #b3d4fc;
    color: #FFFFFF;
    cursor: not-allowed;
}

.giveaway .file-upload-btn {
    background: #005eab;
    padding: 14px 0;
    border: 0px;
    width: 300px;
    margin: 20px;
    overflow: hidden;
    font: 1.25em/120% sans-serif, Arial, Helvetica;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    border-radius: 3px;
    cursor: pointer;
    -webkit-transition: background 0.2s ease-out 0;
    -moz-transition: background 0.2s ease-out 0s;
    -o-transition: background 0.2s ease-out 0;
    transition: background 0.2s ease-out 0;
    text-decoration: none;
    text-transform: uppercase;
}

.giveaway .file-upload h3 {
    color: #666;
}

.giveaway .file-upload-btn:hover {
    background: #b3d4fc;
    color: #FFFFFF;
    -webkit-transition: background 0.2s ease-out 0;
    -moz-transition: background 0.2s ease-out 0s;
    -o-transition: background 0.2s ease-out 0;
    transition: background 0.2s ease-out 0;
    text-decoration: none;
}

.giveaway .file-upload-btn:active {
    border: 0;
    transition: all .2s ease;
}

.giveaway .file-upload-content {
    display: none;
    text-align: center;
}

.giveaway .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.giveaway .image-upload-wrap {
    margin-top: 20px;
    border: 4px dashed #005eab;
    position: relative;
}

.giveaway .image-title-wrap {
    padding: 0 15px 15px 15px;
    color: #666;
}

.giveaway .drag-text {
    text-align: center;
}

.giveaway .drag-text h3 {
    font-weight: 100;
    text-transform: uppercase;
    color: #666;
    padding: 45px 0 20px 0;
}

.giveaway .file-upload-image {
    max-width: 100%;
    margin: auto;
    padding: 20px;
}

.giveaway .remove-image {
    background: #cd4535;
    padding: 14px 0;
    border: 0px;
    width: 300px;
    overflow: hidden;
    font: 1.25em/120% sans-serif, Arial, Helvetica;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    border-radius: 3px;
    cursor: pointer;
    -webkit-transition: background 0.2s ease-out 0;
    -moz-transition: background 0.2s ease-out 0s;
    -o-transition: background 0.2s ease-out 0;
    transition: background 0.2s ease-out 0;
    text-decoration: none;
    text-transform: uppercase;
}

.giveaway .remove-image:hover {
    background: #c13b2a;
    color: #FFFFFF;
    -webkit-transition: background 0.2s ease-out 0;
    -moz-transition: background 0.2s ease-out 0s;
    -o-transition: background 0.2s ease-out 0;
    transition: background 0.2s ease-out 0;
    text-decoration: none;
}

.giveaway .remove-image:active {
    border: 0;
    transition: all .2s ease;
}

.giveaway .errors {
    font-size: 12px;
    color: red;
    margin-top: 3px;
}

/* giveaway photos */
.giveaway .wrapper {
    width: 100%;
    text-align: center;
}

.giveaway .polaroid {
    background: #fff;
    padding: 1rem;
    padding-bottom: 5px;
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.2);
}

.giveaway .polaroid .caption {
    font-family: StoneSansITCPro-Medium, sans-serif;
    font-size: 1.5rem;
    text-align: left;
    line-height: 2em;
    padding-top: 8px;
    position: relative;
}

.giveaway .polaroid .caption p {
    padding: 0 80px 2px 5px !important;
    margin: 0 !important;
    font-size: 12px;
    line-height: 1.6;
    color: #666 !important;
    text-transform: uppercase;
}

.giveaway .polaroid .caption p.place {
    text-transform: uppercase;
}

.giveaway .polaroid .caption p .fa {
    margin-right: 5px;
}

.giveaway .polaroid .caption h4 {
    color: #005ca9 !important;
    margin: 4px 0 2px;
    padding: 0 80px 0 5px !important;
    text-transform: uppercase;
}

.giveaway .polaroid .caption span.likes {
    font-family: StoneSansITCPro-Medium, sans-serif;
    font-size: 1.8rem;
    color: silver;
    text-align: right;
    float: right;
    line-height: 2em;
    background-color: transparent;
    position: absolute;
    right: 10px;
    top: 23px;
}

.giveaway .polaroid .caption span .fa {
    margin-left: 5px;
}

.giveaway .photo-item {
    display: inline-block;
    margin-top: 5px;
    max-width: 33%;
}

.giveaway .photo-item > .fa {
    color: #fff;
    font-size: 3rem;
    top: 3.5rem;
    left: 3.5rem;
    position: absolute;
    cursor: pointer;
    transition: all 0.35s;
    opacity: 0.8;
    padding: 10px 10px 7px 10px;
    background: #999;
    border-radius: 100%;
}

.giveaway .photo-item > .fa:hover {
    color: crimson;
    opacity: 1;
}

.giveaway .photo-item > .fa.liked {
    color: crimson;
    opacity: 1;
    background: transparent;
}

.giveaway .photo-item .polaroid:before {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.35s;
}

.giveaway .photo-item:nth-of-type(4n+1) {
    transform: scale(0.8, 0.8) rotate(5deg);
    transition: all 0.35s;
}

.giveaway .photo-item:nth-of-type(4n+1) .polaroid:before {
    transform: rotate(6deg);
    height: 20%;
    width: 47%;
    bottom: 30px;
    right: 12px;
    box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.4);
}

.giveaway .photo-item:nth-of-type(4n+2) {
    transform: scale(0.8, 0.8) rotate(-5deg);
    transition: all 0.35s;
}

.giveaway .photo-item:nth-of-type(4n+2) .polaroid:before {
    transform: rotate(-6deg);
    height: 20%;
    width: 47%;
    bottom: 30px;
    left: 12px;
    box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.4);
}

.giveaway .photo-item:nth-of-type(4n+4) {
    transform: scale(0.8, 0.8) rotate(3deg);
    transition: all 0.35s;
}

.giveaway .photo-item:nth-of-type(4n+4) .polaroid:before {
    transform: rotate(4deg);
    height: 20%;
    width: 47%;
    bottom: 30px;
    right: 12px;
    box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.3);
}

.giveaway .photo-item:nth-of-type(4n+3) {
    transform: scale(0.8, 0.8) rotate(-3deg);
    transition: all 0.35s;
}

.giveaway .photo-item:nth-of-type(4n+3) .polaroid:before {
    transform: rotate(-4deg);
    height: 20%;
    width: 47%;
    bottom: 30px;
    left: 12px;
    box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.3);
}

.giveaway .photo-item:hover {
    filter: none;
    transform: scale(1, 1) rotate(0deg) !important;
    transition: all 0.35s;
}

.giveaway .photo-item:hover .polaroid:before, .giveaway .photo-item.single-item .polaroid:before {
    content: '';
    position: absolute;
    z-index: -1;
    transform: rotate(0deg);
    height: 90%;
    width: 90%;
    bottom: 0%;
    right: 5%;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.2);
    transition: all 0.35s;
}

.giveaway .polaroid img {
    width: 100%;
}

.giveaway .photo-item.single-item {
    max-width: 100%;
    margin-top: 2em;
}

.giveaway .photo-item.single-item:nth-of-type(4n+1) {
    transform: scale(1, 1);
}

.giveaway .social-share {
    padding: 30px 0;
}

.giveaway .giveaway-pagination {
    display: block;
}

.giveaway a.photo-item:hover {
    text-decoration: none;
}

@media (max-width: 992px) {
    .giveaway .product-box.description-small {
        float: none;
    }

    .giveaway .breakpoint {
        display: block;
        float: left;
        width: 100%;
    }

    .giveaway .giveaway-h2 {
        font-size: 20px !important;
    }

    .giveaway .file-upload-btn {
        width: auto;
    }

    .giveaway .polaroid img {
        width: 100%;
    }

    .giveaway .photo-item:nth-of-type(4n+1), .giveaway .photo-item:nth-of-type(4n+2), .giveaway .photo-item:nth-of-type(4n+3), .giveaway .photo-item:nth-of-type(4n+4) {
        transform: scale(1);
    }

    .giveaway .photo-item {
        display: inline-block;
        margin-top: 2rem;
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .giveaway label {
        width: 100%;
        padding-bottom: 5px;
    }

}

/*==================
Project entry form
==================*/
.padbot20 {
    padding-bottom: 20px;
}

.prijava-container {
    width: 100%;
    max-width: 1170px;
}

.prijava-title h1 {
    font-size: 32px !important;
}

.prijava-title h1 span {
    float: left;
    background-position: -630px -40px;
    width: 35px;
    height: 35px;
    margin-left: -44px;
}

.prijava h3 {
    margin-top: 30px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    color: #005ca9;
    font-size: 20px;
}

.prijava h3 strong, .prijava h4 strong {
    font-size: 15px;
    font-family: 'ItcStoneSansMedium', 'Source Sans Pro', Calibri, Candara, Arial, sans-serif !important;
}

.prijava label {
    margin-top: 0;
    font-size: 14px;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
    font-family: 'ItcStoneSansMedium', 'Source Sans Pro', Calibri, Candara, Arial, sans-serif !important;
}

.prijava textarea {
    height: 150px;
}

textarea.opis-promocije, textarea.opis-provedenog-projekta {
    height: auto;
}

.prijava input#submit_btn_var {
    background-color: #04b675 !important;
    font-family: StoneSansITCPro-Medium, sans-serif;
    color: #fff;
    font-size: 16px;
    /* float: left; */
    padding: 7px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    text-decoration: none !important;
}
.prijava input#submit_btn_var:hover {
    opacity: .7;
}

.prijava .prihvatam-uslove {
    margin-top: 20px;
    padding: 15px 0;
}

.prijava .submt {
    border-top: 1px solid #ccc;
    padding-top: 0px;
    margin-top: 30px;
}

.prijava .prihvatam-uslove {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.prijava label.prihvatam-uslove {
    margin: 0;
    padding: 0;
    padding-left: 35px;
    font-size: 14px;
}

.prijava .prihvatam-uslove input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.prijava .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-top: 2px solid #999;
}

.prijava .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.prijava .prihvatam-uslove input:checked ~ .checkmark:after {
    display: block;
}

.prijava .prihvatam-uslove .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #282b34;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.prijava .error {
    color: red;
    margin-top: 0;
}
form#prijava_form .col-sm-6 {
    height: 85px;
}

.col-height {
    height: auto !important;
    min-height: 95px;
}

.pravila-uslovi a.button-green {
    background-color: #04b675 !important;
    font-family: StoneSansITCPro-Medium, sans-serif;
    color: #fff;
    font-size: 16px;
    /* float: left; */
    padding: 7px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    text-decoration: none !important;
}

.hvala-page {
    background: #ECECEC !important;
}
.hvala-title {
    text-decoration: none;
    color: initial;
}
.hvala-title-link:hover {
    text-decoration: none;
}

.hvala-message {
    margin-top: 30px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    color: #005ca9;
    font-size: 26px;
}

#hvala-slika {
    display: block;
    float: left;
    width: 100%;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 400px;
    margin-bottom: 180px;
}
/* End of Project entry form */

/*==================
Blog page redesign - slug = zivot-
==================*/
.blog-page-design .fullbackground {
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: -1;
}

.blog-page-design .full-width-content {
    text-align: center;
}

.blog-page-design .full-width-content h1 span {
    position: absolute;
}



.blog-page-design .box {
    float: left;
}
.inner-section.group .blog-article:nth-child(2n+1), #blogoviholder .box:nth-child(2n+1), .product-list .box:nth-child(2n+1), .product-box2:nth-child(2n+1), .products-group .box {
    margin-left: 0;
}

.blog-page-design .span_1_of_2 {
    width: 49.2% !important;
}

a.blog-title:link, a.blog-title:visited, a.blog-title:hover {
    color: #282b34;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 1em;
    /*font-style: italic;*/
}

.blog-txt {
    width: 50%;
    float: right;
    background-color: #e2ecf5;
    height: 235px;
    overflow: hidden!important;
}

.blog-txt-inner {
    padding: 30px;
}

a.blog-title {
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}

a.opsirnije-blog {
    color: #005ca9;
    font-style: normal;
    text-decoration: none;
    position: relative;
}

a.opsirnije-blog span {
    background-image: url(../images/arrow-blue.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 10px;
    height: 6px;
    position: absolute;
    right: -20px;
    top: 50%;
    margin-top: -3px;
}

.blog-inner-content {
    overflow: hidden;
}

.blog-slika{
    overflow: hidden;
}

.blog-link{
    width:100%;
    text-align:center;
}

@media only screen and (max-width: 800px) and (min-width: 320px) {
    .blog-page-design .span_2_of_2, .blog-page-design .span_1_of_2 {
        width: 100% !important;
    }
    .blog-page-design .box {
        margin: 1% 0 1% 0 !important;
    }

}

@media only screen and (max-width: 640px) {
    .blog-slika {
        width: 100%;
        float: none;
    }
}

@media only screen and (max-width: 640px) {
    .blog-txt {
        width: 100%;
        float: none;
    }
}
/* End of Blog page redesign - slug = zivot- */


/*==================
 Page content full width
==================*/
.content.full-width-content {
    width: 100% !important;
}
/* End of Page content full width */

#lead-independent .grecaptcha-badge{
    display: none !important;
}
/* Forma za placanje */
.forma-za-placanje {
    width: 85%;
    padding: 85px 0;
    margin: 0 auto;
}

#forma-za-placanje .inner-section {
    padding-top: 50px;
}

.forma-za-placanje a.hide {
    display: block !important;
}

input[name=pikpay] {
    background: url(/assets/images/frontend/pikpay-icon.png);
    border: 0;
    display: block;
    height: 162px;
    width: 320px;
    color : transparent;
}

input[name=epay] {
    background: url(/assets/images/frontend/e-pay-icon.png);
    border: 0;
    display: block;
    height: 147px;
    width: 275px;
    color : transparent;
}

.forma-za-placanje h1 {
    font-size: 32px;
    line-height: 42px;
    padding-left: 42px;
}

.forma-za-placanje h1 span {
    float: left;
    background-position: -630px -40px;
    width: 35px;
    height: 35px;
    margin-left: -44px;
}

.forma-za-placanje p {
    padding-top: 10px;
    line-height: 22px;
    text-align: justify;
}

.opcije-placanja {
    margin-top: 40px;
    text-align: center;
}

.opcije-placanja p {
    text-align: center;
}

.forma-input-fields {
    padding: 40px 0;
}

.forma-input-fields .form-group {
    width: 70%;
    margin: 0 auto;

}
/* End of Forma za placanje */

/*NEW FOOTER STYLE*/
.greyGradientRepeat {
    background: #f4f4f4;
}

.inner-section.group {
    position: relative;
    z-index: 2;
}

footer .footer-links {
    padding: 40px 0;
    width: 92%;
    margin: 0 auto;
}
.group:before, .group:after {
    content: "";
    display: table;
}
.footer-links-menu {
    float: left;
    width: 35%;
}
.footer-links-map {
    float: right;
    background: #fff;
    width: 61% !important;
}
footer .footer-links .box {
    background-color: #f4f4f4;
    border-bottom: 1px solid #c6c7c7;
    margin: 1px 0 0;
    padding: 0 10px 0 20px;
    width: 100%;
}
.box:nth-child(5n+1) {
    margin-left: 0;
}
footer .footer-links .box h4 {
    cursor: pointer;
    font-family: 'ItcStoneSansMedium', sans-serif;
    height: 35px;
    line-height: 39px;
    height: 55px;
    line-height: 57px;
    margin: 0;
    padding: 0;
    position: relative;
}
footer .footer-links h4 {
    font-size: 1em;
    font-weight: bold;
    font-family: 'ItcStoneSansMedium', sans-serif;
}
footer .footer-links i.size35.plus {
    background: url(../assets/files/pages/black_plus.svg) no-repeat 8px 11px !important;
    background-size: 16px auto !important;
}
footer .footer-links i.size35 {
    /*background-position: -1540px -41px;*/
    display: block;
    left: auto;
    right: 0;
    top: 9px;
    position: absolute;
}
footer .footer-links .links-group {
    display: none;
    margin-bottom: 15px;
}
.size35 {
    height: 35px;
    width: 35px;
}
.footer-links a.arrow, .footer-links a.arrow:link, .footer-links a.arrow:visited {
    display: block;
    font-weight: 700;
    height: auto;
    line-height: 16px;
    margin-bottom: 5px;
    padding: 0;
    position: relative;
    text-decoration: none;
}
.footer-links-map .main_department {
    display: inline-block;
    width: 38%;
    margin-left: 5%;
    font-size: 13px;
    float: left;
    padding-top: 30px;
    padding-bottom: 30px;
}
.footer_logo {
    width: 120px;
    height: auto;
    margin-bottom: 30px;
}
.footer-links-map .main_department h3 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
}
.footer-links-map .main_department .address, .footer-links-map .main_department .phone, .footer-links-map .main_department .email {
    margin-bottom: 10px;
}
.footer-links-map .main_department strong {
    color: #767575;
    margin-right: 5px;
    font-weight: normal;
}
footer .footer-links .box.lst {
    border-bottom: none !important;
}
a.footer-alone {
    font-family: 'ItcStoneSansMedium', sans-serif;
    height: 35px;
    line-height: 39px;
    margin: 0;
    padding: 0;
    display: block;
    font-size: 1em;
    font-weight: bold;
    color: #282b34 !important;
}
.footer-links-map .main_department .email a, .footer-links-map .main_department .phone a {
    height: auto;
    color: #005ca9;
    font-size: 13px;
    line-height: 1.4 !important;
    text-decoration: none;
    display: inline;
}
.footermap_block-wrap {
    width: 55%;
    float: right;
    box-sizing: border-box;
}
.footermap_block-wrap h3 {
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
}
.footermap_block {
    /*height: 300px;*/
    background: #fff;
    padding: 10px 30px 30px 20px;
}
.clearfix:before, .clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.group:after {
    clear: both;
}
.group::after {
    content: "";
    display: table;
}

.inner-section.group.copyright {
    position: relative;
}
.group:before, .group:after {
    content: "";
    display: table;
}
.copyright .box.span_1_of_6:first-child {
    display: block;
    height: 26px;
    padding-top: 3px;
}
.box:nth-child(5n+1) {
    margin-left: 0;
}
.cf:before, .cf:after {
    content: " ";
    display: table;
}
.copyright {
    font-size: .857em;
    height: 25px;
    line-height: 25px;
    padding: 7px 0 8px;
    text-align: center;
}
.copyright .box {
    margin-top: 0;
    margin-bottom: 0;
}
.copyright label, ul.feature-list li div {
    display: block;
    float: left;
    line-height: 22px;
}
div.languages {
    top: -1px;
    position: relative;
}
.cf:after {
    clear: both;
}

.copyright .box.span_4_of_6 {
    line-height: 14px;
    padding-top: 5px;
}

a.new-social i {
    display: inline-block;
    position: relative;
    background: #666;
    font-size: 17px;
    line-height: 25px;
    width: 25px;
    color: #fff;
    height: 25px;
    padding-top: 0;
    margin-top: 0;
}
a.new-social {
    display: inline-block !important;
    margin-left: 3px;
    height: 35px !important;
    line-height: 35px !important;
}
.box.span_1_of_6.footer_left_block {
    bottom: 7px;
    float: right;
    /*position: absolute;*/
    /*right: 0;*/
    width: 344px;
}
footer a:link, footer a:visited {
    color: #999;
    display: block;
    font-size: .929em;
    font-style: normal;
    line-height: 22px;
    text-decoration: none;
}

/*Media queries Footer accordion*/
/*@media only screen and (max-width: 1024px) and (min-width: 320px) {*/
/*.greyGradientRepeat {*/
/*background: #c6c7c7;*/
/*}*/
/*}*/

@media screen and (max-width: 420px) {
    .inner-section.group {
        display: block;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 320px) {
    .footer-links-menu {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 320px) {
    footer .footer-links .box {
        background-color: #f4f4f4;
        margin: 0px 0 0 0;
        padding: 0 10px 0 20px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 320px) {
    footer .footer-links .box h4.footer-font {
        height: 55px !important;
        line-height: 57px !important;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 320px) {
    .footer-links-map {
        width: 100% !important;
    }
}
@media only screen and (max-width: 700px) and (min-width: 320px) {
    .footer-links-map .main_department {
        float: none;
        display: block;
        margin-bottom: 15px;
        width: auto;
    }
}
@media only screen and (max-width: 700px) and (min-width: 320px) {
    .footermap_block-wrap {
        float: none;
        width: 100%;
    }
}
@media (max-width: 768px) {
    .footer .bottom .trademarks {
        text-align: center;
    }
}
/*END of NEW FOOTER STYLE*/

/*==================
Hotfix - Footer social icons
==================*/
.instagram-footer {
    background-image: url(/../../assets/images/frontend/instagram-gray.png);
    background-repeat: no-repeat;
}
.instagram-footer:hover {
    background-image: url(/../../assets/images/frontend/instagram-purple.png);
}
.linkedin-footer {
    background-image: url(/../../assets/images/frontend/linkedin-gray.png);
    background-repeat: no-repeat;
}
.linkedin-footer:hover {
    background-image: url(/../../assets/images/frontend/linkedin-blue.png);
}
/* End of Hotfix - Footer social icons */


/*NEW HOMEPAGE PRODUCTS LISTING STYLE*/
#homepage .gray {
    background-color: #fff;
    padding-bottom: 0;
}
h3.usluge-home {
    text-align: center;
    margin-bottom: 0;
}
.grid-products .inner-section {
    padding: 0;
}

.grid-products .inner-section .group {
    max-width: unset;
    padding-bottom: 30px;
}
.grid-products .item .title {
    color: #fff;
    font-size: 24px;
    margin-bottom: 10px;
}
.grid-products .item p {
    font-size: 16px;
    padding-top: 0;
    margin: 0;
}
.grid-products .item {
    margin-left: 0;
    padding-top: 20px;
}

.grid-products .icon {
    position: relative;
    margin: 0 auto;
}

.product-0 {
    padding-left: 25px;
    background: #0059A1;
}
.product-1 {
    background: #0095D5;
}
.product-2 {
    padding-right: 25px;
    background: #6abaff;
<<<<<<< Updated upstream
}
.product-padding.product-3 {
    background: #14b9d8;
}
.product-padding.product-4 {
    background: #00B0D1;
}
.product-padding.product-5 {
    background: #05a0bd;
}
.product-padding {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 35px;
    padding-right: 35px;
    margin-top: 10px;
    color: #fff;
    text-align: center;
}
/*END of NEW HOMEPAGE PRODUCTS LISTING STYLE*/

/*STATISTICS SECTION ON HOMEPAGE*/
.mp_more {
    background: #fff;
    padding-bottom: 30px;
}
@media only screen and (min-width: 1600px)
header, .biggerHeight, .section, footer {
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
}

    .mp_more h2 {
        font-size: 30px;
        text-align: center;
        margin: 0;
        padding-top: 45px;
        padding-bottom: 30px;
    }
    .mp_more_wrapper {
        text-align: center;
        margin-bottom: 40px;
        margin-top: 20px;
    }
    .mp-more_item {
        display: inline-block;
        width: 220px;
    }
    .mp-more_item .icon-newp {
        font-size: 80px;
        margin-bottom: 10px;
        color: #999999;
    }
    .mp-more_item span {
        font-size: 30px;
        line-height: 30px;
    }

    .mp-more_item p {
        margin: 6px auto 0;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
    }
    .mp_more.section .mp_more_text {
        padding: 0 30px;
    }
    .mp_more_text {
        text-align: center;
        font-size: 18px;
        line-height: 26px;
        color: #767575;
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    .mp_more_text a, a.map-more_btn {
        color: #005aab;
        font-style: normal;
        font-size: 16px;
        text-decoration: none;
        display: inline-block;
        position: relative;
    }
    .mp_more_text_second {
        display: none;
    }

    a.mp_hide_btn {
        display: none;
    }

    .mp_more_btn, .mp_hide_btn {
        cursor: pointer;
    }

    img.uniqa-icon {
        height: 80px;
        padding-bottom: 10px;
    }

    @media only screen and (max-width: 767px) and (min-width: 320px) {
        .mp-more_item {
            margin-bottom: 35px;
        }
        img.uniqa-icon {
            height: 60px;
        }
    }

/*END of STATISTICS SECTION ON HOMEPAGE*/

.form-payment-container {
    margin-top: 25px;
}

.yt-full-wrap {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}
.yt-full-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.yt-flash .close-flash-box{
    position: absolute;
    right: 0;
    top: 15px;
    font-size: 120%;
    color: #fff;
    cursor: pointer;
    margin-top: -40px;
}

.yt-flash .flash-box{
    background:none;
}
    /*AWARDS CAROUSEL - HOMEPAGE*/
.product-padding.product-3 {
    background: #14b9d8;
}
.product-padding.product-4 {
    background: #00B0D1;
}
.product-padding.product-5 {
    background: #05a0bd;
}
.product-padding {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 35px;
    padding-right: 35px;
    margin-top: 10px;
    color: #fff;
    text-align: center;
}
/*END of NEW HOMEPAGE PRODUCTS LISTING STYLE*/

/*STATISTICS SECTION ON HOMEPAGE*/
.mp_more {
    background: #fff;
    padding-bottom: 30px;
}
@media only screen and (min-width: 1600px)
header, .biggerHeight, .section, footer {
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
}

    .mp_more h2 {
        font-size: 30px;
        text-align: center;
        margin: 0;
        padding-top: 45px;
        padding-bottom: 30px;
    }
    .mp_more_wrapper {
        text-align: center;
        margin-bottom: 40px;
        margin-top: 20px;
    }
    .mp-more_item {
        display: inline-block;
        width: 220px;
    }
    .mp-more_item .icon-newp {
        font-size: 80px;
        margin-bottom: 10px;
        color: #999999;
    }
    .mp-more_item span {
        font-size: 30px;
        line-height: 30px;
    }

    .mp-more_item p {
        margin: 6px auto 0;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
    }
    .mp_more.section .mp_more_text {
        padding: 0 30px;
    }
    .mp_more_text {
        text-align: center;
        font-size: 18px;
        line-height: 26px;
        color: #767575;
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    .mp_more_text a, a.map-more_btn {
        color: #005aab;
        font-style: normal;
        font-size: 16px;
        text-decoration: none;
        display: inline-block;
        position: relative;
    }
    .mp_more_text_second {
        display: none;
    }

    a.mp_hide_btn {
        display: none;
    }

    .mp_more_btn, .mp_hide_btn {
        cursor: pointer;
    }

    img.uniqa-icon {
        height: 80px;
        padding-bottom: 10px;
    }

    @media only screen and (max-width: 767px) and (min-width: 320px) {
        .mp-more_item {
            margin-bottom: 35px;
        }
        img.uniqa-icon {
            height: 60px;
        }
    }

    /*END of STATISTICS SECTION ON HOMEPAGE*/


    .form-payment-container {
        margin-top: 25px;
    }

    /*AWARDS CAROUSEL - HOMEPAGE*/

    /*.mp-slider {*/
        /*position: relative;*/
        /*max-width: 1600px;*/
        /*margin-left: auto;*/
        /*margin-right: auto;*/
        /*background: #f4f4f4;*/
        /*padding-top: 35px;*/
        /*padding-bottom: 50px;*/
    /*}*/

    #smallCarousel {
        position: relative;
        max-width: 1600px;
        margin-left: auto;
        margin-right: auto;
        background: #f4f4f4;
        padding-top: 35px;
        padding-bottom: 50px;
    }

    section.awards-carousel {
        padding-top: 20px;
    }

    .mp-slider h2 {
        font-size: 24px;
        line-height: normal;
        text-align: center;
        margin: 0;
        margin-bottom: 30px;
    }

    .mp-slider-wrapper {
        padding: 0 60px;
        position: relative;
    }

    .smallDirections.prev {
        position: absolute;
        top: 7px;
        left: 50%;
        margin-left: -390px;
        pointer-events: none;
    }

    .smallDirections a {
        display: block;
        width: 40px;
        height: 70px;
        position: relative;
    }

    .smallDirections.prev a:before {
        left: 13px;
        transform: rotate(45deg);
        top: 4px;
    }

    .smallDirections a:before {
        position: absolute;
        content: '';
        width: 3px;
        height: 36px;
        background: #e1e1e1;
        top: 0;
    }

    .smallDirections.prev a:after {
        left: 13px;
        transform: rotate(135deg);
        bottom: 6px;
    }

    .smallDirections a:after {
        position: absolute;
        content: '';
        width: 3px;
        height: 36px;
        background: #e1e1e1;
        bottom: 0;
    }

    .smallDirections.next {
        position: absolute;
        top: 7px;
        right: 50%;
        margin-right: -390px;
        pointer-events: none;
    }

    .smallDirections.next a:before {
        right: 13px;
        transform: rotate(135deg);
        top: 4px;
    }

    .smallDirections.next a:after {
        right: 13px;
        transform: rotate(45deg);
        bottom: 6px;
    }

    .mp-slider-item {
        width: 130px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .mp-slider-item img {
        width: auto;
        height: 50px;
    }

    .bx-controls {
        margin-top: -12px;
        position: relative;
        text-align: center;
    }
    .bx-wrapper {
        max-width: 510px !important;
    }
    .bx-viewport {
        height: 90px !important;
    }
    .mp-slider-item {
        height: 90px !important;
    }
    .mp-slider-item img {
        height: 90px !important;
    }

    @media only screen and (max-width: 850px) and (min-width: 320px) {
        .smallDirections.prev {
            left: 10px !important;
            margin-left: 0 !important;
        }
        .mp-slider-wrapper {
            padding: 0 40px;
        }
    }
    @media only screen and (max-width: 850px) and (min-width: 320px) {
        .smallDirections.next {
            right: 10px !important;
            margin-right: 0 !important;
        }
    }

    /*END of AWARDS CAROUSEL*/

    /*HOMEPAGE MAP SECTION*/
    .mp-map {
        max-width: 1600px;
        margin-left: auto;
        margin-right: auto;
    }
    .mp-map_bg {
        background: url(/assets/files/pages/mp-map.jpg) no-repeat center;
        background-size: cover;
        padding: 50px 20px;
    }

    .mp-map h2 {
        font-size: 24px;
        line-height: normal;
        text-align: center;
        margin: 0;
        margin-bottom: 80px;
    }
    .mp-map h2 span {
        font-size: 22px;
        color: #0059ab;
        display: block;
    }

    .newcalc-content-wrapper.mp-map_wrapper {
        max-height: 100%;
        padding: 0;
    }

    .newcalc-content-wrapper {
        margin-top: 35px;
        padding-bottom: 20px;
        max-height: 200px;
        overflow: hidden;
        position: relative;
        transition: 0.5s ease-out;
        box-sizing: border-box;
    }
    .mp-map_wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .mp-map_item {
        width: 50%;
        box-sizing: border-box;
    }
    .mp-map_item:first-child {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        padding-right: 225px;
    }

    .mp-map_item_text {
        max-width: 370px;
    }
    .mp-map_item_text p {
        margin: 0;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 22px;
    }
    .mp-map_item:last-child {
        padding-left: 225px;
    }
    .cont-btn.map-more_btn {
        display: none;
        text-align: center;
        color: #005aab !important;
    }

    @media only screen and (max-width: 1024px) and (min-width: 320px) {
        .mp-map_bg {
            background: url(/assets/files/pages/mp-map-mobile.jpg) no-repeat center !important;
            background-size: auto 100% !important;
            padding: 50px 20px;
        }
    }

    @media only screen and (max-width: 1024px) and (min-width: 320px) {
        .mp-map_wrapper {
            flex-direction: column !important;
            display: block !important;
        }
    }

    @media only screen and (max-width: 1024px) and (min-width: 320px) {
        .mp-map_item:first-child, .mp-map_item:last-child {
            padding: 0px !important;
            text-align: center !important;
            display: block !important;
        }
    }

    @media only screen and (max-width: 1024px) and (min-width: 320px) {
        .mp-map_item {
            width: 100% !important;
        }
    }


    /*END of HOMEPAGE MAP*/

    /*NEWSLETTER NEW*/
    .new-newsletter {
        text-align: center;
    }
    .social .newsletter-container {
        margin: 0 auto;
    }
    .social p {
        min-height: 45px;
    }

    #homepage .social {
        padding: 35px 0 35px 0;
    }
    /*END of NEWSLETTER*/

    /*HOMEPAGE WIDGETS*/
    .image-background {
        /*height: 168px;*/
    }

    @media only screen and (max-width: 768px) {
        .pull-right-if-mobile {
            float: right;
        }
    }

    .image-background img {
        width: 100%;
    }

    .mp-three-feedback_txt {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #homepage .title-container {
        margin-top: 0;
    }

    #homepage a h5 {
        padding: 20px 5px;
        opacity: unset;
        font-size: 16px;
        line-height: normal;
        max-height: unset;
    }
    .mp-three-feedback_item {
        text-align: center;
        box-sizing: border-box;
        border: 1px solid #eaeaea;
        margin-left: 9px;
        margin-right: 9px;
        margin-bottom: 5px;
        padding-bottom: 20px;
        justify-content: space-between;
    }
    .mp-three-feedback_btn {
        padding: 0 10px;
    }
    .mainColor {
        background-color: #005ca9;
    }
    .mp-three-feedback_btn a.button {
        width: 100%;
        max-width: 260px;
        height: 50px;
        text-align: center;
        padding-top: 16px !important;
        font-size: 16px !important;
    }
    .mp-three-feedback_txt {
        height: unset;
        display: block;
    }

    #homepage a.button:hover, a.button:link, a.button:visited, input.button[type="submit"] {
        border: 0 none;
        box-sizing: border-box;
        color: #fff;
        display: inline-block;
        font-size: 16px;
        font-style: normal;
        line-height: 18px;
        min-height: 35px;
        opacity: 1;
        padding: 10px 10px 9px;
        text-decoration: none;
        text-shadow: 0 1px rgba(0, 0, 0, 0.1);
    }
    #homepage .questions-box p {
        margin-top: 25px;
        margin-bottom: 25px;
    }
    #homepage .questions-box .damage-report {
        margin-top: 54px;
    }

    .title-container a {
        text-decoration: none;
        color: inherit;
    }

    /*END of HOMEPAGE WIDGETS*/}


/*Products page listing*/
#page .box .content ul li {
    margin-bottom: 20px;
}
#page .box .content ul li span {
    position: relative;
    height: 24px;
    margin-top: 0;
    background-position: -357px -48px;
}

#page .box-plain .box {
    border: none;
    box-shadow: 0 0 5px rgba(0,0,0,.19);
}

#page .box-plain .box .titles h2 {
    padding-top: 25px;
    font-size: 24px !important;
    line-height: 26px !important;
}

#page .box-plain .box .titles h4 {
    color: #04b675;
}

#page .box-plain .content hr {
    border-top: none;
    height: 15px;
}

#page .box-plain .box .content {
    margin-bottom: 15px;
}

.newcalc-content-wrapper {
    margin-top: 35px;
    padding-bottom: 20px;
    min-height: 203px;
    max-height: 203px;
    overflow: hidden;
    position: relative;
    transition: .5s ease-in-out;
    box-sizing: border-box;
}

.newcalc-content-wrapper:after {
    display: none;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0);
}

.newcalc-content-wrapper.otvoren:after {
    background: none;
    filter: none;
}

.cont-btn {
    display: block;
    color: #999 !important;
    margin-top: 30px;
    font-style: normal !important;
}

.cont-btn span.btn-more {
    display: inline-block;
}
.cont-btn span.btn-less {
    display: none;
}

.cont-btn span.btn-more.otvoren {
    display: none !important;
}
.cont-btn span.btn-less.otvoren {
    display: inline-block !important;
}

#page .box a.cont-btn {
    text-align: center;
}

.cont-btn span {
    display: inline-block;
    position: relative;
    padding-right: 14px;
    text-align: center;
}

.newcalc-content-wrapper.otvoren {
    max-height: 1000px !important;
    transition: .8s ease-in-out;
}

#page .box-plain .more.bottom {
    margin-top: 20px;
}
#page .box-plain .more .empty {
    padding: 11px 20px;
}
/*End of procucts page listing*/

/*Product page listings - Why uniqa?*/
.features-block {
    background: #7fadd4;
    padding: 60px 0px 50px 0px;
    margin-bottom: 30px;
}

.features-block h2 {
    text-align: center !important;
    margin: 0;
    margin-bottom: 40px !important;
    color: #282b34 !important;
    font-size: 24px !important;
    font-weight: normal !important;
}
.features-wrapper {
    margin-left: -1%;
    margin-right: -1%;
    padding: 0 2%;
}
.features-wrapper.leftspace {
    margin-left: 0%;
    margin-right: 0%;
    padding: 0 6.7%;
}
.features-item {
    float: left;
    /*width: 31.33%;*/
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 14px;
    position: relative;
}
.features-img p {
    padding-top: 0 !important;
}

.features-item div.features-img img {
    position: absolute;
    top: 0;
    left: 0;
    height: 45px;
    width: auto;
    max-width: 65px;
    display: inline-block;
    font-size: 45px;
    line-height: 40px;
    color: #282b34 !important;
}
.features-item span {
    font-size: 16px;
    line-height: 20px;
    color: #282b34;
    display: table-cell;
    padding-left: 80px;
    vertical-align: middle;
    height: 35px;
    max-width: 235px;
}

    @media only screen and (max-width: 850px) and (min-width: 320px) {
        .features-item{
            float: none;
        }
    }
/*End of Product page listings - Why uniqa?*/

/*cards at blog*/
.card-columns .card {
    margin-bottom: .75rem;
  }
  
.blog-text{
    padding-top: 32% !important;
  }
  
  .blog-text hr{
    width:180px;
    margin-left: 44%;
  }
  
  .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.4rem;
    overflow: hidden;
  }
  
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    height: 60%;
  }
  
  .card-title {
    margin-bottom: 0.75rem;
    color: black
  }
  

  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
  }
  
  .card-img {
    border-radius: calc(0.25rem - 1px);
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .card-columns .card {
    margin-bottom: 0.75rem;
  }
  
  @media (min-width: 576px) {
    .card-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 1.25rem;
      -moz-column-gap: 1.25rem;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
    }
    .card-columns .card {
      display: inline-block;
     
    }
  }

  @media(max-width: 400px){
    .blog-text hr{
        margin-left: 20%;
      }
      
  }
  
  .cb-prihvatite-uslove-up {
    -webkit-animation: cb-prihvatite-uslove-up 0.5s forwards;
}

.cb-prihvatite-uslove-down {
    -webkit-animation: cb-prihvatite-uslove-down 0.2s forwards;
}

@keyframes cb-prihvatite-uslove-up {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1.3, 1.3);
    }
}

@keyframes cb-prihvatite-uslove-down {
    0% {
        transform: scale(1.3, 1.3);
    }
    100% {
        transform: scale(1, 1);
    }
}

